import { DocumentCode2 } from "iconsax-react";

const icons = {
  samplePage: DocumentCode2,
};

const support = {
  id: "other",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icons.samplePage,
    },
  ],
};

export default support;
