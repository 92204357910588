import React from "react";
import { Dialog } from "@mui/material";
import { PopupTransition } from "components/@extended/Transitions";

const CustomDialog = ({
  maxWidth = "sm",
  fullWidth = true,
  open,
  ariaLabelledby = "alert-dialog-slide-description",
  children,
}) => {
  return (
    <Dialog
      maxWidth={maxWidth}
      TransitionComponent={PopupTransition}
      keepMounted
      fullWidth={fullWidth}
      onClose={()=>false}
      open={open}
      aria-labelledby={ariaLabelledby}
      sx={{
        "& .MuiDialog-paper": { p: 0 },
        transition: "transform 225ms",
        // Adjust the overlay background color and opacity
        backdropFilter: "blur(3px) brightness(0.8)",
        backgroundColor: "rgba(255, 255, 255, 0.3)", // Adjust opacity here
      }}
    >
      {children}
    </Dialog>
  );
};

export default CustomDialog;