import { useEffect, useRef, useState } from "react";
import { Box, Button, Skeleton, Grid } from "@mui/material";
import MainCard from "components/MainCard";
import * as url from "./utils/urlHelper";
import {
  downloadFormat,
  getTitle,
  showToast,
  validationDynamicTable,
} from "services/utils";
import DownloadModal from "components/reusables/DownloadModal";
import { CustomHooks } from "hooks/CustomHooks";
import withRouter from "components/withRouter";
import SkeletonTable from "components/SkeletonTable";
import DynamicTable from "components/reusables/DynamicTable";

const MasterListOfDoc = (props) => {
  const { userData, ApiServices, onChangeDownloadState } = props;
  const { documentId } = CustomHooks.useUrlParams();
  const TempData = useRef(null);
  const [isDownload, setIsDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const handleFrequencyChange = (selectedValue, rowIndex, rows, rowsData) => {
    let frequencyValue = selectedValue.target.value;

    const effectiveDateStr = TempData.current?.[rowIndex]?.effectiveDate;

    if (!effectiveDateStr || !frequencyValue) {
      console.error("Effective Date or Frequency Value is missing!");
      return;
    }

    // Parse Effective Date (split by "/")
    const [day, month, year] = effectiveDateStr
      .split("/")
      .map((val) => parseInt(val, 10));
    let nextDate = new Date(year, month - 1, day);

    // Add Frequency
    if (frequencyValue === "6 months") {
      nextDate.setMonth(nextDate.getMonth() + 6);
    } else if (frequencyValue.includes("year")) {
      const yearsToAdd = parseInt(frequencyValue.split(" ")[0], 10);
      nextDate.setFullYear(nextDate.getFullYear() + yearsToAdd);
    }

    // Format the new date as DD-MM-YYYY
    const formattedNextDate = `${String(nextDate.getDate()).padStart(
      2,
      "0"
    )}-${String(nextDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${nextDate.getFullYear()}`;

    const updatedRows = [...rowsData];
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      frequencyReview: frequencyValue,
      nextReviewDate: formattedNextDate,
    };

    setRows(updatedRows); // Update the state
  };
  const [columns, setColumns] = useState([
    { name: "Sr. No.", value: "srNo" },
    {
      name: "Document No.",
      value: "documentNumber",
      isReadOnly: true,
    },
    {
      name: "Document Title",
      value: "documentName",
      isReadOnly: true,
    },
    {
      name: "Rev. No.",
      value: "revisionNo",
      isReadOnly: true,
    },
    {
      name: "Effective Date",
      value: "effectiveDate",
      isReadOnly: true,
    },
    {
      name: "Frequency of Review",
      value: "frequencyReview",
      type: "dropdown",
      extraOnChange: (selectedValue, rowIndex, columnValue, rows) => {
        handleFrequencyChange(selectedValue, rowIndex, columnValue, rows);
      },
      options: ["6 months", "1 year", "2 year", "3 year", "4 year", "5 year"],
    },
    {
      name: "Next Review Date",
      value: "nextReviewDate",
      isReadOnly: true,
      displayValue: "nextReviewDate",
    },
    {
      name: "Review Date",
      value: "reviewDate",
      type: "date",
    },
  ]);

  const [errors, setErrors] = useState({});

  const handleFormatDataSave = async () => {
    try {
      const validationErrors = validationDynamicTable(rows, columns);
      if (Object.keys(validationErrors)?.length > 0) {
        setErrors(validationErrors);
        return;
      }
      const reqObj = {
        document: { rows, columns },
      };

      let uri = url.UPDATE_MASTER_LIST + documentId;
      let result = await ApiServices.callServicePutWithBodyData(uri, reqObj);
      showToast(result);
      if (result?.meta?.success) {
        getDocument();
      }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER ERROR", error);
    }
  };

  const handleDownload = async () => {
    let reqObj = {
      formatId: documentId,
    };
    let uri = url.DOWNLOAD_COD;
    await downloadFormat(uri, reqObj, onChangeDownloadState);
  };

  const getDocument = async () => {
    try {
      let reqObj = {
        childformatId: documentId,
        documentName: "Master List Of Document",
      };
      let uri = url.GET_MASTER_LIST;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      if (result?.meta?.success) {
        const fetchedDocument = result?.data.document?.rows || [];
        const tableRows = fetchedDocument.map((item, index) => ({
          srNo: index + 1,
          documentNumber: item?.documentNumber || "",
          effectiveDate: item?.effectiveDate || "",
          revisionNo: item?.revisionNumber || "",
          documentName: item?.documentName || "",
          frequencyReview: item?.frequencyReview || "",
          nextReviewDate: item?.nextReviewDate || "",
          reviewDate: item?.reviewDate || "",
        }));
        setRows(result?.data.document?.rows);
        if (result?.data.document?.columns && result?.data.document?.columns.length > 0) {
          setColumns(result?.data.document?.columns)
        } 
        TempData.current = tableRows;
      }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER ERROR", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDocument();
  }, []);

  return (
    <MainCard
      title={getTitle("Master List of Docs")}
      secondary={
        isLoading ? (
          <Skeleton
            variant="rectangular"
            height="30px"
            width="100px"
            sx={{
              borderRadius: "12px",
            }}
          />
        ) : (
          <Button variant="contained" color="success" onClick={handleDownload}>
            Download
          </Button>
        )
      }
    >
      <Grid container spacing={2}>
        {isLoading ? (
          <Grid item xs={12}>
            <SkeletonTable />
          </Grid>
        ) : (
          <>
            <DynamicTable
              columns={columns}
              setColumns={setColumns}
              rows={rows}
              setRows={setRows}
              setErrors={setErrors}
              errors={errors}
              isStrictData={true}
              isNumberDynamic={true}
            />
            {!userData?.externalUser && (
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={handleFormatDataSave}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <DownloadModal isOpen={isDownload} setIsClose={setIsDownload} />
    </MainCard>
  );
};

export default withRouter(MasterListOfDoc);
