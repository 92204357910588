export const allPagesAndTypes = [
  {
    type: "404",
    menu: false,
    path: "/test",
    route: true,
    name: "404",
    view:'users',
    componentName: "Test",
    heading: "Hello users211212",
  },
  {
    type: "404",
    menu: false,
    path: "/test2",
    route: true,
    name: "404",
    view:'teachers',
    componentName: "Test",
    heading: "Hello Teachers",
    subheading:"helllllll"
  },
];
