import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import MainCard from "components/MainCard";
import { getTitle } from "services/utils";
import withRouter from "components/withRouter";
import DynamicFolder from "components/DynamicFolder";
import { GET_LIST_BY_DOCUMENT } from "./utils/urlHelper";

const DocumentList = (props) => {
  const { navigate } = props?.router,
    { ApiServices } = props;

  const [format, setFormat] = useState([]);
  const { moduleName, year, month } = props?.router?.params;

  const [isLoading, setIsLoading] = useState(true);

  const getAllCreatedFormat = async () => {
    setIsLoading(true);
    try {
      let reqObj = {
        modelName: moduleName,
        year: parseInt(year),
        monthName: month,
      };

      let uri = GET_LIST_BY_DOCUMENT;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);

      if (result?.response) {
        setFormat(result.result);
      }
    } catch (error) {
      console.error("GET_LIST_BY_DOCUMENT API ERROR", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCreatedFormat();
  }, []);

  const handleProcedures = async (item) => {
    navigate(
      `/format/by-date/${moduleName}/${year}/${month}/${item.documentName}`
    );
  };

  return (
    <MainCard title={getTitle("Document wise list")}>
      <Box>
        <DynamicFolder
          isLoading={isLoading}
          onClick={handleProcedures}
          data={format}
          title={"documentName"}
          headingMain={`Oops, looks like there are no format created it!`}
          subHeading={`Please create format`}
        />
      </Box>
    </MainCard>
  );
};
export default withRouter(DocumentList);
