import { Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ThemeMode } from "config";

// Function to generate Tooltip components with custom properties
const CustomTooltip = ({ title, children, onClick, placement }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor:
              mode === ThemeMode.DARK
                ? theme.palette.grey[50]
                : theme.palette.grey[700],
            opacity: 0.9,
          },
        },
      }}
      title={title}
      placement={placement}
    >
      <span onClick={onClick}>{children}</span>
    </Tooltip>
  );
};

export default CustomTooltip;
