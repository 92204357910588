import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const CustomSelect = ({
  name,
  label,
  value,
  onChange,
  options,
  error,
  helperText,
  fullWidth = true,
  isMultiSelect = false,
}) => {
  return (
    <FormControl fullWidth={fullWidth} error={Boolean(error)}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        value={value || (isMultiSelect ? [] : "")}
        onChange={onChange}
        label={label}
        multiple={isMultiSelect}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error ? helperText : ""}</FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;
