import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box, tooltipClasses, Tooltip as MuiTooltip } from "@mui/material";
import getColors from "utils/getColors";
import withRouter from "components/withRouter";

const getVariantStyle = ({ color, theme, labelColor }) => {
  const colors = getColors(theme, color);
  const { main, contrastText } = colors;
  let colorValue = color ? color : "";

  if (
    ["primary", "secondary", "info", "success", "warning", "error"].includes(
      colorValue
    )
  ) {
    return {
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: main,
        color: labelColor ? labelColor : contrastText,
      },
      [`& .${tooltipClasses.arrow}`]: {
        color: main,
      },
    };
  } else {
    return {
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colorValue,
        color: labelColor ? labelColor : contrastText,
        boxShadow: theme.shadows[1],
      },
      [`& .${tooltipClasses.arrow}`]: {
        color: colorValue,
      },
    };
  }
};

// ==============================|| TOOLTIP - COLOR STYLED ||============================== //

const TooltipStyle = styled(
  ({ className, ...props }) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (prop) => prop !== "color" && prop !== "labelColor",
  }
)(({ theme, color, labelColor }) => ({
  ...(color && getVariantStyle({ color, theme, labelColor })),
}));

// ==============================|| TOOLTIP - EXTENDED ||============================== //

const CustomTooltip = (props) => {
  const { children, theme, arrow, labelColor = "", ...rest } = props;
  return (
    <Box display="flex">
      <TooltipStyle
        arrow={arrow}
        {...rest}
        theme={theme}
        labelColor={labelColor}
      >
        {children}
      </TooltipStyle>
    </Box>
  );
};

CustomTooltip.propTypes = {
  children: PropTypes.element,
  arrow: PropTypes.bool,
  labelColor: PropTypes.string,
  rest: PropTypes.array,
};

export default withRouter(CustomTooltip);
