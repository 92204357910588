import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import Main from "../Main";

const createRoute = (path, element, children) => ({ path, element, children });

const DynamicDocumentRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [createRoute("dynamic-document", null, [createRoute("manage", <Main />)])]
);

export default DynamicDocumentRoute;
