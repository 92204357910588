import PropTypes from "prop-types";
import { useEffect } from "react";
import withRouter from "./withRouter";

const ScrollTop = (props) => {
  const { children } = props,
    { location } = props?.router;
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return children || null;
};

ScrollTop.propTypes = {
  children: PropTypes.node,
};

export default withRouter(ScrollTop);
