import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const SkeletonTable = ({
  rowCount = 5,
  columnCount = 5,
  cellWidth = "100px",
  cellHeight = 12,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {Array(columnCount)
              .fill()
              .map((_, index) => (
                <TableCell key={index}>
                  <Skeleton
                    variant="rectangular"
                    height={cellHeight}
                    width={cellWidth}
                    sx={{
                      margin: "10px 0",
                      borderRadius: "12px",
                    }}
                  />
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(rowCount)
            .fill()
            .map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {Array(columnCount)
                  .fill()
                  .map((_, cellIndex) => (
                    <TableCell key={cellIndex}>
                      <Skeleton
                        variant="rectangular"
                        height={cellHeight}
                        width={cellWidth}
                        sx={{
                          margin: "10px 0",
                          borderRadius: "12px",
                        }}
                      />
                    </TableCell>
                  ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SkeletonTable;
