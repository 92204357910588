import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ButtonBase } from "@mui/material";
import withRouter from "components/withRouter";

const LogoSection = (props) => {
  const { sx, to, images, objectFit, borderRadius, height, width } = props,
    { APP_DEFAULT_PATH } = props?.config;
  return (
    <ButtonBase
      disableRipple
      component={Link}
      to={!to ? APP_DEFAULT_PATH : to}
      sx={sx}
    >
      <img
        src={images}
        alt="Logo"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: width ?? "auto",
          height: height ?? "auto",
          objectFit: objectFit,
          borderRadius: borderRadius,
        }}
      />
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string,
};

export default withRouter(LogoSection);
