import PropTypes from "prop-types";
import Default from "./default";

const Theme = (presetColor,mode) => {
  return Default(mode);
};

Theme.propTypes = {
  colors: PropTypes.object,
  presetColor: PropTypes.any,
};

export default Theme;
