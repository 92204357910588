import React, { useState } from "react";
import { Box, Tabs, Tab, Divider } from "@mui/material";
import { TabPanel } from "services/utils";

const TabsContainer = ({
  variant = "standard",
  tabLabels,
  tabContents,
  setTabValue,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    if (setTabValue) setTabValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        variant={variant}
        onChange={handleChange}
        aria-label="tabs container"
      >
        {tabLabels.map((label, index) => (
          <Tab key={index} label={label} />
        ))}
      </Tabs>
      <Divider />
      {tabContents?.map((ContentComponent, index) => (
        <TabPanel key={index} value={value} index={index}>
          <ContentComponent />
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabsContainer;
