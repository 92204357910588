import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// project-imports
import { APP_DEFAULT_PATH } from "config";
import useAuth from "hooks/useAuth";
import { Constants } from "services/keyConstants";
import { getItem, setItem } from "services/utils";

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(
        location?.state?.from ? location?.state?.from : APP_DEFAULT_PATH,
        {
          state: {
            from: "",
          },
          replace: true,
        }
      );
      let userData = getItem(Constants.KEY_USER_DATA);
      let selectedRole = getItem(Constants.KEY_SELECTED_ROLE);
      if (!selectedRole) {
        setItem(Constants.KEY_SELECTED_ROLE, userData?.designationArr?.[0] || "");
      }
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
