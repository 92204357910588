import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";

// Define lazy-loaded components
const InternalCommunication = Loadable(
  lazy(() => import("../InternalCommunication"))
);
const InternalCommunicationById = Loadable(
  lazy(() => import("../InternalCommunicationById"))
);
const List = Loadable(lazy(() => import("../List")));
const YearList = Loadable(lazy(() => import("../YearList")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const InternalCommunicationRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.INTERNAL_COMMUNICATION, null, [
      createRoute("view/:year", <InternalCommunication />),
      createRoute(":id", <InternalCommunicationById />),
      createRoute("list/:year", <List />),
      createRoute("year-list", <YearList />),
    ]),
  ]
);

export default InternalCommunicationRoute;
