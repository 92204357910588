import { useState, useCallback } from "react";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import { dispatchFunction, getSecureToken } from "services/utils";
import { Constants } from "services/keyConstants";
import { useLocation } from "react-router-dom";

// Utility function for handling errors
const handleApiError = (error, customMessage) => {
  console.error(customMessage, error);
  return "An error occurred. Please try again later.";
};
const userData = getSecureToken(Constants.KEY_USER_DATA);

export const CustomHooks = {
  useGetAllRole: () => {
    const [roleData, setRoleData] = useState(null);
    const [roleError, setRoleError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [roleOptions, setRoleOptions] = useState([]);

    const getAllRole = useCallback(async () => {
      setIsLoading(true);
      setRoleError(null);
      try {
        const formatObj = { orgId: userData?.orgId };
        const result = await ApiServices.callServicePostWithBodyData(
          url.GET_ALL_ROLES_API,
          formatObj
        );
        dispatchFunction(result);
        if (result?.response) {
          let designations = result?.result?.data?.map(
            (item) => item?.designation
          );
          let finalDesignations = [...new Set(designations)];
          setRoleOptions(finalDesignations);
          setRoleData(result?.result?.data);
        } else {
          setRoleError("Failed to fetch roles. Please try again.");
        }
      } catch (error) {
        setRoleError(handleApiError(error, "GET_ALL_ROLES ERROR"));
      } finally {
        setIsLoading(false);
      }
    }, []);

    return { roleOptions, roleData, roleError, isLoading, getAllRole };
  },

  useUrlParams: () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  },

  usePostApiCall: () => {
    const [postApidata, setPostApiData] = useState(null);
    const [postApiError, setPostApiError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const postApiCall = useCallback(async ({ reqObj, urlRouteTo }) => {
      setIsLoading(true);
      setPostApiError(null);
      try {
        const result = await ApiServices.callServicePostWithBodyData(
          urlRouteTo,
          reqObj
        );
        if (result?.meta?.success) {
          setPostApiData(result?.data);
        } else if (result?.response) {
          setPostApiData(result?.result?.data);
        } else {
          setPostApiError(
            `Failed to get ${urlRouteTo}. Please check your input.`
          );
        }
      } catch (error) {
        setPostApiError(handleApiError(error, `${urlRouteTo}_ERROR`));
      } finally {
        setIsLoading(false);
      }
    }, []);

    return { postApidata, postApiError, isLoading, postApiCall };
  },

  usePutApiCall: () => {
    const [putApidata, setPutApiData] = useState(null);
    const [putApiError, setPutApiError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const putApiCall = useCallback(async ({ reqObj, urlRouteTo }) => {
      setIsLoading(true);
      setPutApiError(null);
      try {
        const result = await ApiServices.callServicePutWithBodyData(
          urlRouteTo,
          reqObj
        );
        dispatchFunction(result);
        if (result.response) {
          setPutApiData(result.result.data);
        } else {
          setPutApiError(
            `Failed to get ${urlRouteTo}. Please check your input.`
          );
        }
      } catch (error) {
        setPutApiError(handleApiError(error, `${urlRouteTo}_ERROR`));
      } finally {
        setIsLoading(false);
      }
    }, []);

    return { putApidata, putApiError, isLoading, putApiCall };
  },

  useGetApiCall: () => {
    const [getApidata, setGetApiData] = useState(null);
    const [getApiError, setGetApiError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getApiCall = useCallback(async ({ urlRouteTo }) => {
      setIsLoading(true);
      setGetApiError(null);
      try {
        const result = await ApiServices.callServiceGet(urlRouteTo);
        dispatchFunction(result);
        if (result.response) {
          setGetApiData(result.result.data);
        } else {
          setGetApiError(
            `Failed to get ${urlRouteTo}. Please check your input.`
          );
        }
      } catch (error) {
        setGetApiError(handleApiError(error, `${urlRouteTo}_ERROR`));
      } finally {
        setIsLoading(false);
      }
    }, []);

    return { getApidata, getApiError, isLoading, getApiCall };
  },
};
