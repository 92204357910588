import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import Avatar from "components/@extended/Avatar";
import { PopupTransition } from "components/@extended/Transitions";
import { Trash } from "iconsax-react";
import { memo, useEffect, useRef } from "react";
import { isNotNullOrUndefined } from "services/utils";

const DeleteModal = memo((props) => {
  const {
    open,
    handleModal,
    title = "Are you sure?",
    description = "You won't be able to revert this!",
    confirmButtonText = "Delete",
    cancelButtonText = "Cancel",
    iconColor = "error",
    icon = <Trash variant="Bold" />,
    handleDelete,
    field,
  } = props;
  const data = useRef(null);

  // for setting selected data value in state variable
  useEffect(() => {
    const handleSetData = (event) => {
      const { detail } = event || {};
      data.current = detail || {};
    };

    window.addEventListener("deleteData", handleSetData);

    return () => {
      window.removeEventListener("deleteData", handleSetData);
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => handleModal()}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      sx={{
        "& .MuiDialog-paper": {
          width: "444px",
          maxWidth: "444px",
          margin: "0 auto",
        },
      }}
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar
            color={iconColor}
            sx={{ width: 72, height: 72, fontSize: "1.75rem" }}
          >
            {icon}
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h4" align="center">
              {isNotNullOrUndefined(data.current?.[field])
                ? `${title?.slice(0, -1)} ${data.current?.[field]} ?`
                : title}
            </Typography>
            <Typography align="center">{description}</Typography>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button
              fullWidth
              onClick={() => handleModal()}
              color="secondary"
              variant="outlined"
            >
              {cancelButtonText}
            </Button>
            <Button
              fullWidth
              color="error"
              variant="contained"
              onClick={() => handleDelete()}
              autoFocus
            >
              {confirmButtonText}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
});

export default DeleteModal;
