import { useState } from "react";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";
import SimpleBar from "components/third-party/SimpleBar";
import { ThemeMode } from "config";
import { Add, NotificationStatus } from "iconsax-react";
import comingSoon from "assets/images/comingSoon.svg";
import withRouter from "components/withRouter";

const Customization = (props) => {
  const { theme } = props;
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "secondary.200" : "secondary.200";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK
      ? "background.default"
      : "secondary.100";

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <IconButton
          color="secondary"
          variant="light"
          onClick={handleToggle}
          aria-label="settings toggler"
          size="large"
          sx={{
            color: "secondary.main",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
            p: 1,
          }}
        >
          <NotificationStatus variant="Bulk" />
        </IconButton>
      </Box>
      <Drawer
        sx={{ zIndex: 2001 }}
        anchor="right"
        onClose={handleToggle}
        open={open}
        PaperProps={{ sx: { width: { xs: 350, sm: 474 } } }}
      >
        {open && (
          <MainCard
            content={false}
            sx={{ border: "none", borderRadius: 0, height: "100vh" }}
          >
            <SimpleBar
              sx={{
                height: "100vh",
                "& .simplebar-content": {
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <Box sx={{ p: 2.5 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1.5}
                >
                  <Typography variant="h5"></Typography>
                  <IconButton
                    color="secondary"
                    sx={{ p: 0 }}
                    onClick={handleToggle}
                  >
                    <Add size={28} style={{ transform: "rotate(45deg)" }} />
                  </IconButton>
                </Stack>
              </Box>
              <Box sx={{ height: "100%" }}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  src={comingSoon}
                  alt="comingSoon"
                />
              </Box>
            </SimpleBar>
          </MainCard>
        )}
      </Drawer>
    </>
  );
};

export default withRouter(Customization);
