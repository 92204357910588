import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import MainCard from "components/MainCard";
import { getTitle } from "services/utils";
import withRouter from "components/withRouter";
import DynamicFolder from "components/DynamicFolder";
import { GET_DATA_BY_YEAR } from "./utils/urlHelper";

const FormatListByYear = (props) => {
  const { navigate } = props?.router,
    { ApiServices } = props;

  const [format, setFormat] = useState([]);
  const { moduleName } = props?.router?.params;

  const [isLoading, setIsLoading] = useState(true);

  // Function to transform folder names into objects
  const transformData = (years) => {
    return years.map((year) => ({
      documentName: year,
    }));
  };

  const getAllCreatedFormat = async () => {
    setIsLoading(true);
    try {
      let uri = GET_DATA_BY_YEAR + moduleName;
      let result = await ApiServices.callServiceGet(uri);

      if (result?.result?.type === "success") {
        setFormat(transformData(result?.result?.data));
      }
    } catch (error) {
      console.error("GET_DATA_BY_YEAR API ERROR", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCreatedFormat();
  }, []);

  const handleProcedures = async (item) => {
    navigate(`/format/by-month/${moduleName}/${item.documentName}`);
  };

  return (
    <MainCard title={getTitle("Year wise list")}>
      <Box>
        <DynamicFolder
          isLoading={isLoading}
          onClick={handleProcedures}
          data={format}
          title={"documentName"}
          headingMain={`Oops, looks like there are no format created it!`}
          subHeading={`Please create format`}
        />
      </Box>
    </MainCard>
  );
};
export default withRouter(FormatListByYear);
