import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  Skeleton,
} from "@mui/material";
import MainCard from "components/MainCard";
import {
  downloadFormat,
  getTitle,
  showToast,
  validationDynamicTable,
} from "services/utils";
import * as url from "./utils/urlHelper";
import { CustomHooks } from "hooks/CustomHooks";
import withRouter from "components/withRouter";
import SkeletonTable from "components/SkeletonTable";
import DynamicTable from "components/reusables/DynamicTable";

const ExternalListOfDoc = (props) => {
  const { userData, ApiServices, onChangeDownloadState } = props,
    { navigate } = props?.router;
  const { documentId, procedureId } = CustomHooks.useUrlParams();
  const handleSubmitDataRef = useRef(null);
  const [externalOriginDocData, setExternalOriginDocData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [columns, setColumns] = useState([
    {
      name: " Sr. No.",
      value: "srNo",
    },
    {
      name: "Title",
      value: "title",
      validation: {
        required: true,
        msg: "title is required",
      },
    },
  ]);

  const [rows, setRows] = useState([]);
  const [errors, setErrors] = useState({});


  const handleDownload = async () => {
    let reqObj = {
      formatId: documentId,
    };
    let uri = url.DOWNLOAD_COD;
    await downloadFormat(uri, reqObj, onChangeDownloadState);
  };

  const updateDocument = async () => {
    try {
      const validationErrors = validationDynamicTable(rows, columns);
      if (Object.keys(validationErrors)?.length > 0) {
        setErrors(validationErrors);
        return;
      }
      const reqObj = {
         document: { rows,columns },
      };

      let uri = url.GET_OR_UPDATE_EXTERNAL_LIST + documentId;
      let result = await ApiServices.callServicePutWithBodyData(uri, reqObj);
      showToast(result);
      if (result?.meta?.success) {
        getDocument();
      }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER ERROR", error);
    }
  };

  const getDocument = async () => {
    try {
      let uri = url.GET_OR_UPDATE_EXTERNAL_LIST + `/${documentId}`;
      let result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        const documents = result?.data?.document ?? [];
      const normalizedDocuments = Array.isArray(documents) ? documents : [documents];
      const normalizedRows = normalizedDocuments.flatMap(doc => doc.rows || []);
      const normalizedColumns = normalizedDocuments?.flatMap(doc => doc?.columns || []);
      setExternalOriginDocData({
        ...result.data,
        document: normalizedDocuments,
      });
      setRows(normalizedRows);
      if (normalizedColumns && normalizedColumns.length > 0) {
        setColumns(normalizedColumns);
      }     
    }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER ERROR", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDocument();
  }, [procedureId, documentId]);

  return (
    <MainCard
      title={getTitle("External Origin Docs")}
      secondary={
        isLoading ? (
          <Skeleton
            variant="rectangular"
            height="30px"
            width="100px"
            sx={{
              borderRadius: "12px",
            }}
          />
        ) : (
          !userData?.externalUser && (
            <Button
              variant="contained"
              color="success"
              onClick={handleDownload}
            >
              Download
            </Button>
          )
        )
      }
    >
      <Grid container spacing={2}>
        {isLoading ? (
          <Grid item xs={12}>
            <SkeletonTable />
          </Grid>
        ) : (
          <> 
            <DynamicTable
              columns={columns}
              setColumns={setColumns}
              rows={rows}
              setRows={setRows}
              setErrors={setErrors}
              errors={errors}
              handleSubmitRef={handleSubmitDataRef}
            />
            {!userData?.externalUser && (
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap=".5rem"
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => updateDocument()}
                >
                  Save
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </MainCard>
  );
};

export default withRouter(ExternalListOfDoc);
