import React from "react";
import { FormControl, TextField } from "@mui/material";

const CustomTextField = ({
  name,
  label,
  value,
  onChange,
  error,
  helperText,
  fullWidth = true,
}) => {
  return (
    <FormControl fullWidth={fullWidth} error={Boolean(error)}>
      <TextField
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        error={Boolean(error)}
        helperText={helperText}
      />
    </FormControl>
  );
};

export default CustomTextField;
