export const GET_PRO_FORMAT = `/cod/pro-format`;

export const POST_FORMAT = `/cod/format`;

export const GET_BY_PROFORMAT_ID = `/cod/format/by-proformatId`;

export const POST_VERSION_LIST = `/cod/format/version-list`;

export const GET_OR_UPDATE_EXTERNAL_LIST = `/cod/format/`;

export const GET_MASTER_LIST = `/cod/format/get-masterlist`;

export const UPDATE_MASTER_LIST = `/cod/format/`;

export const DOWNLOAD_COD = `/cod/format/download`;
