const baseUrl = "/qmsSet/format";

export const ADD_DATA = `${baseUrl}/`;
export const GET_DATA_BY_YEAR = `${baseUrl}/get-by-year/`;
export const GET_LIST_MONTH = `${baseUrl}/get-months`;
export const GET_LIST_BY_DOCUMENT = `${baseUrl}/get-list-by-month`;
export const GET_LIST_BY_DATE = `${baseUrl}/get-formats-id`;

export const GET_ALL_DATA_BY_TYPE = `${baseUrl}/get-dynamic`;
export const GET_BY_ID= `${baseUrl}/`
export const GET_ALL_CREATED_FORMAT = `${baseUrl}/procedure/get`;

export const UPDATE_DATA = `${baseUrl}/update-specific`;


