import React, { useEffect, useState } from "react";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import CustomDialog from "components/reusables/CustomDialog";
import { showToast } from "services/utils";
import withRouter from "./withRouter";

const DynamicNotification = (props) => {
  const {
    open,
    setOpen,
    version,
    isMultipleDepartment = false,
    isMultipleUser = false,
    handleSubmit,
    isUserSelection = false,
    isMultipleUserSelection = false,
    isStaticDepartment = "",
    ApiServices,
    userData,
    commonUrl,
  } = props;
  const [organogramData, setOrganogramData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(
    isStaticDepartment?.length > 0 ? isStaticDepartment : []
  );
  const [selectedDesignation, setSelectedDesignation] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [finalUsersData, setFinalUsersData] = useState([]);
  const [headUsers, setHeadUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const handleClose = () => {
    setOpen(false);
    setSelectedDepartment([]);
  };
  const submitUsers = () => {
    if (isUserSelection) {
      if (selectedUsers?.length > 0) {
        handleSubmit(selectedUsers);
        handleClose();
      } else {
        showToast(
          `Please select a user to for the above designation${
            isMultipleUserSelection ? "s" : ""
          }.`
        );
      }
    } else {
      const userData = findUserDataByDesignation(
        organogramData,
        selectedDesignation
      );
      const finalUserData = userData?.filter(
        (item) => item?.userId?.length > 0
      );
      setFinalUsersData(finalUserData);
      if (finalUserData?.length > 0) {
        handleSubmit(finalUserData);
        handleClose();
      } else {
        showToast(
          `Please assign a user to all the above designation${
            isMultipleDepartment ? "s" : ""
          }.`
        );
      }
    }
  };

  const findUserDataByDesignation = (data, designationArray) => {
    let results = [];

    const search = (node) => {
      if (node?.designation && designationArray?.includes(node?.designation)) {
        results?.push({ ...node?.userData, designation: node?.designation });
      }
      if (node?.children && node?.children?.length) {
        node?.children?.forEach((child) => search(child));
      }
    };

    data?.forEach((node) => search(node));
    return results;
  };

  const getAllDepartments = (data) => {
    let result = [];

    const recurse = (nodes) => {
      nodes?.forEach((node) => {
        if (node?.children && node?.children.length > 0) {
          if (node.title != "Managing Director" && node.title != "Director") {
            result.push(node?.designation);
          }
          recurse(node?.children); // Recursively check the children
        }
      });
    };

    recurse(data);
    return result;
  };

  const filterDepartments = (data, selectedDepartments) => {
    const result = [];

    const recursiveSearch = (items) => {
      for (const item of items) {
        if (selectedDepartments?.includes(item?.designation)) {
          if (item?.children) {
            for (const itm of item?.children) {
              result?.push(itm?.designation);
            }
          }
        } else if (item?.children) {
          recursiveSearch(item?.children);
        }
      }
    };
    recursiveSearch(data);
    const uniqueArray = [...new Set(result)];
    // return result;
    return uniqueArray;
  };
  const filterUserBasedBasedOnDesignation = (
    data,
    selectedDepartments,
    selectedDesignations
  ) => {
    const result = [];
    const recursiveSearch = (items) => {
      for (const item of items) {
        if (selectedDepartments?.includes(item?.designation)) {
          if (item?.children) {
            recursiveSearch(item?.children);
          }
        } else if (item?.children) {
          recursiveSearch(item?.children);
        }

        if (selectedDesignations.includes(item?.designation)) {
          result.push(item?.userData);
        }
      }
    };
    recursiveSearch(data);
    const uniqueArray = [...new Set(result)];
    return uniqueArray;
  };

  const handleUsers = (value) => {
    setSelectedDesignation(value);
  };

  const getHeadOfAllDepartment = async () => {
    let reqObj = {
      isHead: true,
      orgId: userData?.orgId,
      version: version ?? "v00",
    };
    let uri = commonUrl?.GET_HEAD_DATA_BY_ORGANOGRAM;
    let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    if (result?.response) {
      let data = result?.result?.data?.allDepartmentHeads;
      let dataWithOutDirectorAndMd = data?.filter((item) => !item.title);
      let filterData = dataWithOutDirectorAndMd?.map((item) => item?.userData);
      setHeadUsers(filterData);
      setOrganogramData(result?.result?.data?.organogramData);
    }
  };

  const getAllRoles = async () => {
    try {
      let reqObj = {
        orgId: userData?.orgId,
      };
      let uri = commonUrl?.GET_ALL_ROLES_API;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);

      if (result?.meta?.success) {
        getHeadOfAllDepartment();
        let finalDepartment = result?.data?.filter(
          (item) => typeof item?.userName === "object"
        );
        const filteredData = finalDepartment?.filter(
          (item) =>
            Object.keys(item?.userName).length > 0 &&
            item?.userName?.userId !== "" &&
            delete item?.userName?.responsibilities
        );
        setAllUsers(filteredData);
      }
    } catch (error) {
      console.error("GET_ALL_ROLES_API ERROR", error);
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  return (
    <div>
      <CustomDialog open={open} onCancel={(e) => handleClose(e)} maxWidth="sm">
        <DialogTitle>
          Select Departments and Designation to notify users
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Department</InputLabel>
                <Select
                  multiple={isMultipleDepartment}
                  value={selectedDepartment}
                  onChange={(e) => {
                    setSelectedDepartment(e?.target?.value),
                      setSelectedDesignation([]),
                      setSelectedUsers([]);
                  }}
                  disabled={isStaticDepartment?.length > 0}
                  displayEmpty
                  renderValue={(selected) =>
                    isMultipleDepartment
                      ? selected?.length === 0
                        ? ""
                        : selected?.join(", ")
                      : selected
                  }
                >
                  {getAllDepartments(organogramData)?.map((dept, index) => (
                    <MenuItem key={index} value={dept}>
                      {dept}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {selectedDepartment?.length > 0 && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Select Designation</InputLabel>
                  <Select
                    multiple={isMultipleUser}
                    value={selectedDesignation || []}
                    onChange={(e) => {
                      handleUsers(e?.target?.value), setSelectedUsers([]);
                    }}
                    displayEmpty
                    renderValue={(selected) =>
                      isMultipleUser
                        ? selected?.length === 0
                          ? ""
                          : selected?.join(", ")
                        : selected
                    }
                    placeholder="Select"
                  >
                    <MenuItem disabled value="">
                      <em>Select</em>
                    </MenuItem>
                    {filterDepartments(organogramData, selectedDepartment)?.map(
                      (dept, index) => (
                        <MenuItem key={index} value={dept}>
                          {dept}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {selectedDesignation?.length > 0 && isUserSelection && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Select Users</InputLabel>
                  <Select
                    multiple={isMultipleUserSelection}
                    value={selectedUsers?.map((item) => item?.name) || []}
                    onChange={(e) => {
                      let tempData = []; // Initialize tempData

                      if (isMultipleUserSelection) {
                        // Process tempData only if multiple selection is allowed
                        tempData = e.target.value?.filter((item) => {
                          if (typeof item === "object" && item !== null) {
                            return !selectedUsers?.some(
                              (user) => user?.userId === item?.userId
                            );
                          }
                          return false; // Exclude non-object items
                        });
                      }

                      setSelectedUsers(
                        isMultipleUserSelection
                          ? [...selectedUsers, ...tempData]
                          : [e?.target?.value]
                      );
                    }}
                    displayEmpty
                    renderValue={(selected) =>
                      isMultipleUserSelection
                        ? selected?.length === 0
                          ? ""
                          : selected?.join(", ")
                        : selected
                    }
                    placeholder="Select"
                  >
                    <MenuItem disabled value="">
                      <em>Select</em>
                    </MenuItem>
                    {filterUserBasedBasedOnDesignation(
                      organogramData,
                      selectedDepartment,
                      selectedDesignation
                    )?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2.5 }}>
          <Button variant="contained" color="error" onClick={handleClose}>
            Close
          </Button>
          <Button variant="contained" color="success" onClick={submitUsers}>
            Submit
          </Button>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};
export default withRouter(DynamicNotification);
