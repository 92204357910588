import React, { useEffect, useImperativeHandle, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Skeleton,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showToast } from "services/utils";
import successImg from "assets/images/verify.png";
import { CREATE_FEEDBACK_PUBLIC } from "./utils/urlHelper";
import withRouter from "components/withRouter";

const FeedbackForm = (props) => {
  const { privateRoute, id, orgId, formikRef, ApiServices } = props,
    { navigate } = props?.router;
  const [responce, setResponce] = useState(false);
  const [isLoading, setIsLoading] = useState(privateRoute ? false : true);
  const feedbackValidationSchema = Yup.object({
    feedbackData: Yup.object().shape({
      productDescription: Yup.string().required("This field is required"),
      timesUsed: Yup.string().required("This field is required"),
      improvementSuggestion: Yup.string().required("This field is required"),
      packingSuggestion: Yup.string().required("This field is required"),
      pricingSuggestion: Yup.string().required("This field is required"),
      suitableApplication: Yup.string().required("Please select an option"),
      pricing: Yup.string().required("Please select an option"),
      quality: Yup.string().required("Please select an option"),
      packing: Yup.string().required("Please select an option"),
      deliveryTime: Yup.string().required("Please select an option"),
    }),
    userData: Yup.object().shape({
      name: Yup.string().required("This field is required"),
      address: Yup.string().required("This field is required"),
      pin: Yup.string()
        .matches(/^\d{6}$/, "PIN must be 6 digits")
        .required("This field is required"),
      mobile: Yup.string()
        .matches(/^\d{10}$/, "Telephone must be 10 digits")
        .required("This field is required"),
      fax: Yup.string()
        .matches(/^\d{10}$/, "Fax must be 10 digits")
        .required("This field is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("This field is required"),
    }),
  });
  const feedbackCustomerData = useFormik({
    initialValues: {
      feedbackData: {
        productDescription: "",
        timesUsed: "",
        regularUser: false,
        suitableApplication: "",
        pricing: "",
        quality: "",
        packing: "",
        deliveryTime: "",
        improvementSuggestion: "",
        packingSuggestion: "",
        pricingSuggestion: "",
      },
      userData: {
        name: "",
        address: "",
        pin: "",
        mobile: "",
        fax: "",
        email: "",
      },
    },
    validationSchema: feedbackValidationSchema,
    onSubmit: async (values) => {
      let reqObj = {
        parentId: id,
        formatName: "Feedback",
        documentName: "Feedback",
        prefixType: "FormatPrefixes",
        type: "External",
        subType: "Generate-Link",
        document: {
          feedbackData: {
            status: {
              createdAt: new Date().toISOString(),
              comment: null,
              currentStatus: "Pending",
              clientUserId: null,
            },
            ...values,
          },
        },
        orgId: orgId,
      };
      if (formikRef?.current === undefined || formikRef?.current === null) {
        let uri = CREATE_FEEDBACK_PUBLIC;
        let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
        if (result?.meta?.success) {
          showToast(result);
          setResponce(result?.data);
        } else {
          showToast(result);
        }
      }
    },
  });

  useEffect(() => {
    if (!privateRoute) {
      const timer = setTimeout(() => setIsLoading(false), 250);
      return () => clearTimeout(timer);
    }
  }, []);

  // Expose submitForm, values, and errors using useImperativeHandle
  useImperativeHandle(formikRef, () => ({
    submitForm: () => {
      feedbackCustomerData.handleSubmit();
    },
    feedbackCustomerData: feedbackCustomerData,
  }));

  return (
    <>
      {responce ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img height={"100px"} width={"100px"} src={successImg} alt="done" />
            <span style={{ textAlign: "center" }}>
              Data Submmitted Successfully
            </span>
          </div>
        </>
      ) : isLoading ? (
        <>
          <TableContainer component={Paper} sx={{ margin: "1rem 0rem" }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={1} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      <Skeleton variant="text" width="100px" height={20} />
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Skeleton variant="text" width="100%" height={20} />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      <Skeleton variant="text" width="100px" height={20} />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={20} />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      <Skeleton variant="text" width="100px" height={20} />
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ alignItems: "center" }}>
                    <Box
                      display="flex"
                      gap="15px"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Skeleton variant="text" height={40} width={60} />
                      <Typography variant="h6" marginTop="6px" fontSize="17px">
                        <Skeleton variant="text" width="50px" height={20} />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>

                {Array.from(new Array(5)).map((_, index) => (
                  <TableRow>
                    {Array.from(new Array(4)).map((_, index) => (
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="span" fontWeight="bold">
                          <Skeleton variant="text" width="100px" height={20} />
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

                {Array.from(new Array(2)).map((_, index) => (
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                      <Typography variant="h6" fontWeight="bold">
                        <Skeleton
                          variant="text"
                          width="100px"
                          height={20}
                          style={{ margin: "auto" }}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}

                {Array.from(new Array(2)).map((_, index) => (
                  <>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Skeleton variant="text" width="100%" height={20} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                        <Typography variant="h6" fontWeight="bold">
                          <Skeleton
                            variant="text"
                            width="100px"
                            height={20}
                            style={{ margin: "auto" }}
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                ))}

                {Array.from(new Array(2)).map((_, index) => (
                  <>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Skeleton variant="text" width="100%" height={20} />
                      </TableCell>
                    </TableRow>
                  </>
                ))}

                {Array.from(new Array(2)).map((_, index) => (
                  <>
                    <TableRow>
                      <TableCell colSpan={1} sx={{ textAlign: "center" }}>
                        <Typography variant="h6" fontWeight="bold">
                          <Skeleton variant="text" width="100px" height={20} />
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Skeleton variant="text" width="100%" height={20} />
                      </TableCell>
                    </TableRow>
                  </>
                ))}

                {Array.from(new Array(2)).map((_, index) => (
                  <>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="h6" fontWeight="bold">
                          <Skeleton variant="text" width="100px" height={20} />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" width="100px" height={20} />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="h6" fontWeight="bold">
                          <Skeleton variant="text" width="100px" height={20} />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" width="100px" height={20} />
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <form
          onSubmit={feedbackCustomerData.handleSubmit}
          style={{ width: "100%" }}
        >
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={1} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Product Description:
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <TextField
                      name="feedbackData.productDescription"
                      fullWidth
                      multiline
                      rows={2}
                      value={
                        feedbackCustomerData?.values?.feedbackData
                          ?.productDescription
                      }
                      onChange={feedbackCustomerData.handleChange}
                      error={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.productDescription &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData
                            ?.productDescription
                        )
                      }
                      helperText={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.productDescription &&
                        feedbackCustomerData?.errors?.feedbackData
                          ?.productDescription
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      How Many Times Used?
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name="feedbackData.timesUsed"
                      value={feedbackCustomerData?.values?.timesUsed}
                      onChange={feedbackCustomerData?.handleChange}
                      error={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.timesUsed &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData?.timesUsed
                        )
                      }
                      helperText={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.timesUsed &&
                        feedbackCustomerData?.errors?.feedbackData?.timesUsed
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Regular User
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ alignItems: "center" }}>
                    <Box display="flex" gap="15px">
                      <Switch
                        name="feedbackData.regularUser"
                        checked={
                          feedbackCustomerData?.values?.feedbackData
                            ?.regularUser
                        }
                        onChange={feedbackCustomerData.handleChange}
                        sx={{ transform: "scale(1.3)", marginLeft: "90px" }}
                      />
                      <Typography variant="h6" marginTop="6px" fontSize="17px">
                        {feedbackCustomerData?.values?.feedbackData?.regularUser
                          ? "Yes"
                          : "No"}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      <Typography variant="h6" fontWeight="bold">
                        Suitable For Intended Application?
                      </Typography>
                      <Typography
                        sx={{
                          color: "#dc2626",
                          fontWeight: "400",
                          fontSize: "0.75rem",
                        }}
                      >
                        {feedbackCustomerData?.touched?.feedbackData
                          ?.suitableApplication &&
                          feedbackCustomerData?.errors?.feedbackData
                            ?.suitableApplication}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.suitableApplication &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData
                            ?.suitableApplication
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.suitableApplication"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.suitableApplication === "verySuitable"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.suitableApplication",
                                "verySuitable"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.suitableApplication &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.suitableApplication
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.suitableApplication &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.suitableApplication
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="Very Suitable"
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.suitableApplication &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData
                            ?.suitableApplication
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.suitableApplication"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.suitableApplication === "suitable"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.suitableApplication",
                                "suitable"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.suitableApplication &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.suitableApplication
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.suitableApplication &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.suitableApplication
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="Suitable"
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.suitableApplication &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData
                            ?.suitableApplication
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.suitableApplication"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.suitableApplication === "notSuitable"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.suitableApplication",
                                "notSuitable"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.suitableApplication &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.suitableApplication
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.suitableApplication &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.suitableApplication
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="Not Suitable"
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Pricing
                    </Typography>
                    <Typography
                      sx={{
                        color: "#dc2626",
                        fontWeight: "400",
                        fontSize: "0.75rem",
                      }}
                    >
                      {feedbackCustomerData?.touched?.feedbackData?.pricing &&
                        feedbackCustomerData?.errors?.feedbackData?.pricing}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData?.pricing &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData?.pricing
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.pricing"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.pricing === "high"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.pricing",
                                "high"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.pricing &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.pricing
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.pricing &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.pricing
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="[A] High"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData?.pricing &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData?.pricing
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.pricing"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.pricing === "average"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.pricing",
                                "average"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.pricing &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.pricing
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.pricing &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.pricing
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="[B] Average"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData?.pricing &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData?.pricing
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.pricing"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.pricing === "low"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.pricing",
                                "low"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.pricing &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.pricing
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.pricing &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.pricing
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="[C] Low"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Quality
                    </Typography>
                    <Typography
                      sx={{
                        color: "#dc2626",
                        fontWeight: "400",
                        fontSize: "0.75rem",
                      }}
                    >
                      {feedbackCustomerData?.touched?.feedbackData?.quality &&
                        feedbackCustomerData?.errors?.feedbackData?.quality}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData?.quality &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData?.quality
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.quality"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.quality === "good"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.quality",
                                "good"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.quality &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.quality
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.quality &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.quality
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="[A] Good"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData?.quality &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData?.quality
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.quality"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.quality === "onPar"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.quality",
                                "onPar"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.quality &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.quality
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.quality &&
                                  feedbackCustomerData.errors?.feedbackData
                                    ?.quality
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="[B] On Par"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData?.quality &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData?.quality
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.quality"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.quality === "poor"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.quality",
                                "poor"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.quality &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.quality
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.quality &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.quality
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="[C] Poor"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Packing
                    </Typography>
                    <Typography
                      sx={{
                        color: "#dc2626",
                        fontWeight: "400",
                        fontSize: "0.75rem",
                      }}
                    >
                      {feedbackCustomerData?.touched?.feedbackData?.packing &&
                        feedbackCustomerData?.errors?.feedbackData?.packing}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData?.packing &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData?.packing
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.packing"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.packing === "good"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.packing",
                                "good"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.packing &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.packing
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.packing &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.packing
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="[A] Good"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData?.packing &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData?.packing
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.packing"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.packing === "onPar"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.packing",
                                "onPar"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.packing &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.packing
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.packing &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.packing
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="[B] On Par"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData?.packing &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData?.packing
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.packing"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.packing === "poor"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.packing",
                                "poor"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.packing &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.packing
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.packing &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.packing
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="[C] Poor"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Delivery Time
                    </Typography>
                    <Typography
                      sx={{
                        color: "#dc2626",
                        fontWeight: "400",
                        fontSize: "0.75rem",
                      }}
                    >
                      {feedbackCustomerData?.touched?.feedbackData
                        ?.deliveryTime &&
                        feedbackCustomerData?.errors?.feedbackData
                          ?.deliveryTime}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.deliveryTime &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData
                            ?.deliveryTime
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.deliveryTime"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.deliveryTime === "good"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.deliveryTime",
                                "good"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.deliveryTime &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.deliveryTime
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.deliveryTime &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.deliveryTime
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="[A] Good"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.deliveryTime &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData
                            ?.deliveryTime
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.deliveryTime"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.deliveryTime === "onPar"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.deliveryTime",
                                "onPar"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.deliveryTime &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.deliveryTime
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.deliveryTime &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.deliveryTime
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="[B] On Par"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControl
                      error={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.deliveryTime &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData
                            ?.deliveryTime
                        )
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="feedbackData.deliveryTime"
                            checked={
                              feedbackCustomerData?.values?.feedbackData
                                ?.deliveryTime === "poor"
                            }
                            onChange={() =>
                              feedbackCustomerData.setFieldValue(
                                "feedbackData.deliveryTime",
                                "poor"
                              )
                            }
                            sx={{
                              color:
                                feedbackCustomerData?.touched?.feedbackData
                                  ?.deliveryTime &&
                                feedbackCustomerData?.errors?.feedbackData
                                  ?.deliveryTime
                                  ? "#dc2626"
                                  : "inherit",
                              "&.Mui-checked": {
                                color:
                                  feedbackCustomerData?.touched?.feedbackData
                                    ?.deliveryTime &&
                                  feedbackCustomerData?.errors?.feedbackData
                                    ?.deliveryTime
                                    ? "#dc2626"
                                    : "primary.main",
                              },
                            }}
                          />
                        }
                        label="[C] Poor"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Suggestions On
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Improvement in Product
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}>
                    <TextField
                      name="feedbackData.improvementSuggestion"
                      multiline
                      rows={4}
                      fullWidth
                      value={
                        feedbackCustomerData?.values?.feedbackData
                          ?.improvementSuggestion
                      }
                      onChange={feedbackCustomerData?.handleChange}
                      error={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.improvementSuggestion &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData
                            ?.improvementSuggestion
                        )
                      }
                      helperText={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.improvementSuggestion &&
                        feedbackCustomerData?.errors?.feedbackData
                          ?.improvementSuggestion
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Packing of Product
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}>
                    <TextField
                      name="feedbackData.packingSuggestion"
                      multiline
                      rows={4}
                      fullWidth
                      value={
                        feedbackCustomerData?.values?.feedbackData
                          ?.packingSuggestion
                      }
                      onChange={feedbackCustomerData.handleChange}
                      error={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.packingSuggestion &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData
                            ?.packingSuggestion
                        )
                      }
                      helperText={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.packingSuggestion &&
                        feedbackCustomerData?.errors?.feedbackData
                          ?.packingSuggestion
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Pricing of Product
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}>
                    <TextField
                      name="feedbackData.pricingSuggestion"
                      multiline
                      rows={4}
                      fullWidth
                      value={
                        feedbackCustomerData?.values?.feedbackData
                          ?.pricingSuggestion
                      }
                      onChange={feedbackCustomerData.handleChange}
                      error={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.pricingSuggestion &&
                        Boolean(
                          feedbackCustomerData?.errors?.feedbackData
                            ?.pricingSuggestion
                        )
                      }
                      helperText={
                        feedbackCustomerData?.touched?.feedbackData
                          ?.pricingSuggestion &&
                        feedbackCustomerData?.errors?.feedbackData
                          ?.pricingSuggestion
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      User Information
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={1} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Name of User:
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <TextField
                      name="userData.name"
                      fullWidth
                      value={
                        feedbackCustomerData?.values?.feedbackData?.userData
                          ?.name
                      }
                      onChange={feedbackCustomerData.handleChange}
                      error={
                        feedbackCustomerData?.touched?.userData?.name &&
                        Boolean(feedbackCustomerData?.errors?.userData?.name)
                      }
                      helperText={
                        feedbackCustomerData?.touched?.userData?.name &&
                        feedbackCustomerData?.errors?.userData?.name
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={1} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Address:
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <TextField
                      name="userData.address"
                      fullWidth
                      value={
                        feedbackCustomerData?.values?.feedbackData?.userData
                          ?.address
                      }
                      onChange={feedbackCustomerData?.handleChange}
                      error={
                        feedbackCustomerData?.touched?.userData?.address &&
                        Boolean(feedbackCustomerData?.errors?.userData?.address)
                      }
                      helperText={
                        feedbackCustomerData?.touched?.userData?.address &&
                        feedbackCustomerData?.errors?.userData?.address
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Pin:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="userData.pin"
                      fullWidth
                      value={
                        feedbackCustomerData?.values?.feedbackData?.userData
                          ?.pin
                      }
                      onChange={feedbackCustomerData.handleChange}
                      error={
                        feedbackCustomerData?.touched?.userData?.pin &&
                        Boolean(feedbackCustomerData?.errors?.userData?.pin)
                      }
                      helperText={
                        feedbackCustomerData?.touched?.userData?.pin &&
                        feedbackCustomerData?.errors?.userData?.pin
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Telephone:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="userData.mobile"
                      fullWidth
                      value={
                        feedbackCustomerData?.values?.feedbackData?.userData
                          ?.mobile
                      }
                      onChange={feedbackCustomerData.handleChange}
                      error={
                        feedbackCustomerData?.touched?.userData?.mobile &&
                        Boolean(feedbackCustomerData?.errors?.userData?.mobile)
                      }
                      helperText={
                        feedbackCustomerData?.touched?.userData?.mobile &&
                        feedbackCustomerData?.errors?.userData?.mobile
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Fax:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="userData.fax"
                      fullWidth
                      value={
                        feedbackCustomerData?.values?.feedbackData?.userData
                          ?.fax
                      }
                      onChange={feedbackCustomerData.handleChange}
                      error={
                        feedbackCustomerData?.touched?.userData?.fax &&
                        Boolean(feedbackCustomerData?.errors?.userData?.fax)
                      }
                      helperText={
                        feedbackCustomerData?.touched?.userData?.fax &&
                        feedbackCustomerData?.errors?.userData?.fax
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Email:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="userData.email"
                      fullWidth
                      value={
                        feedbackCustomerData?.values?.feedbackData?.userData
                          ?.email
                      }
                      onChange={feedbackCustomerData.handleChange}
                      error={
                        feedbackCustomerData?.touched?.userData?.email &&
                        Boolean(feedbackCustomerData?.errors?.userData?.email)
                      }
                      helperText={
                        feedbackCustomerData?.touched?.userData?.email &&
                        feedbackCustomerData?.errors?.userData?.email
                      }
                    />
                  </TableCell>
                </TableRow>
                {!privateRoute && (
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          width: "100%",
                          gap: "1rem",
                        }}
                      >
                        <Button
                          onClick={() => navigate(`../`)}
                          variant="contained"
                          color="error"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </form>
      )}
    </>
  );
};

export default withRouter(FeedbackForm);
