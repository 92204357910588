import MainCard from "components/MainCard";
import React, { useEffect, useState } from "react";
import { GET_YEAR } from "./utils/urlHelper";
import { ApiServices } from "services/apiServices";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import Error404 from "components/reusables/404";
import CustomFolderSkeleton from "components/CustomFolderSkeleton";
import withRouter from "components/withRouter";
import { routeConstants } from "services/keyConstants";

const PreventiveYearList = (props) => {
  const { navigate } = props?.router
  const [data, setData] = useState([{ name: "2024" }]);

  const [isLoading, setIsLoading] = useState(true);

  const handleNavigate = (check) => {
    if (check === "plan") {
      navigate(`/${routeConstants.PREVENTIVE_MAINTAINCE}/maintenance-plan`);
    } else if (check === "breakdown") {
      navigate(`/${routeConstants.PREVENTIVE_MAINTAINCE}/machine-break-down`);
    } else if (check === "machine") {
      navigate(`/${routeConstants.PREVENTIVE_MAINTAINCE}/master-list-machine`);
    }
  };

  const handleOpenFolder = (year) => {
    navigate(`/${routeConstants.PREVENTIVE_MAINTAINCE}/preventive-maintenance-list/${year}`);
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const result = await ApiServices.callServiceGet(GET_YEAR);
      if (result?.meta?.success) {
        setData(result?.data);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <MainCard
        title={`Preventive Maintenence Year List`}
        secondary={
          <>
            {
              <>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 3 }}
                    onClick={() => {
                      handleNavigate("plan");
                    }}
                  >
                    Create Maintenance Plan
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    style={{ marginRight: 3 }}
                    onClick={() => {
                      handleNavigate("breakdown");
                    }}
                  >
                    Create Machine BreakDown
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      handleNavigate("machine");
                    }}
                  >
                    Create Master List Of Machine
                  </Button>
              </>
            }
          </>
        }
      >
        <Grid container spacing={2}>
          {data?.length === 0 ? (
            <Error404
              headingMain={`Oops, looks like there are no Customer Related Process And Distribution created it!`}
              subHeading={`Please fill Customer Related Process And Distribution`}
            />
          ) : isLoading ? (
            <CustomFolderSkeleton length={12} />
          ) : (
            data?.map((row, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                <Card
                  style={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    onClick={() => handleOpenFolder(row?.year)}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      borderRadius: 2,
                      padding: 2,
                      height: "100%",
                      justifyContent: "center",
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale3d(1.02, 1.02, 1)",
                        transition: "all .10s ease-in-out",
                      },
                    }}
                  >
                    <FolderIcon
                      sx={{
                        fontSize: 50,
                        color: "#5c8ee0",
                        cursor: "pointer",
                      }}
                    />
                    <Typography>{row?.year}</Typography>
                  </Box>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </MainCard>
    </>
  );
};

export default withRouter(PreventiveYearList);
