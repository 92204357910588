import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFormik } from "formik";
import CustomDialog from "components/reusables/CustomDialog";
import CustomSelect from "components/atom/CustomSelect";
import CustomTextField from "components/atom/CustomTextField";
import { showToast } from "services/utils";
import * as url from "../utils/urlHelper";
import withRouter from "components/withRouter";

const AddEditFormat = (props) => {
  const {
    isOpen,
    setIsOpen,
    modalMode = "add",
    setModalMode,
    type,
    getData,
    ApiServices,
    validationSchema,
    moduleType,
    initialValues,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  const formatOptions = (data) =>
    data?.map((item) => ({ label: item?.name, value: item?._id }));

  const [formFields, setFormFields] = useState([
    {
      name: "procedure",
      label: "Select Procedure",
      type: "select",
      initialValue: null,
      options: [],
      extraOnChange: (event, values, setFieldValue, options) => {
        const selectedItem = options?.find(
          (option) => option?.value === event?.target?.value
        );
        setFieldValue("procedure", selectedItem);
        setFormFields((prev) =>
          prev.map((field) =>
            field.name === "part"
              ? {
                  ...field,
                  options: formatOptions(selectedItem?.item?.parts) ?? [],
                }
              : field
          )
        );
      },
    },
    {
      name: "part",
      label: "Select Part",
      type: "select",
      hiddenField: "procedure",
      options: [],
      hidden: (values) => !values?.procedure?.item?.parts?.length,
      extraOnChange: (event, values, setFieldValue, options) => {
        const selectedItem = options?.find(
          (option) => option.value === event?.target?.value
        );
        setFieldValue("part", selectedItem);
        if (data.current !== null) {
          let selectedDocument = data.current?.parts?.find(
            (item) => item?.partId === event?.target?.value
          )?.result;
          setFieldValue("documents", selectedDocument);
        }
      },
    },
    {
      name: "documents",
      label: "Document Name",
      type: "array",
      fields: [
        {
          name: "name",
          label: "Document Name",
          type: "textField",
        },
        {
          name: "viewType",
          label: "View Type",
          type: "select",
          options: [
            { label: "List", value: "List" },
            { label: "Year", value: "Year" },
          ],
        },
      ],
    },
  ]);

  const getProcedureData = async () => {
    setIsLoading(true);
    try {
      const reqObj = { moduleType: "procedure" };
      const queryString = new URLSearchParams(reqObj).toString();
      const selectedFields = "name,defaultName,_id,parts";
      const result = await ApiServices.callServiceGet(
        `${url.GET_ALL}?${queryString}&selectFields=${selectedFields}`
      );
      const procedureOptions = result?.data?.map((item) => ({
        label: item?.name,
        value: item?._id,
        item: item,
      }));

      setFormFields((prevFields) => [
        {
          ...prevFields?.[0],
          options: procedureOptions,
        },
        ...prevFields?.slice(1),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) getProcedureData();
  }, [isOpen]);

  const data = useRef(null);

  const handleModal = (mode = "add", item = null) => {
    setIsOpen(!isOpen);
    setModalMode(mode);
    if (mode === "add") {
      formik.resetForm();
    } else if (mode === "edit" && item) {
      formik.setValues(item);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const isAddMode = modalMode === "add";

      let reqObj = {
        moduleType: moduleType,
        documents: values?.documents,
        procedure: values?.procedure?.value,
        ...(values?.part !== null && { partId: values?.part?.value }),
      };

      const apiMethod = isAddMode ? url.CREATE_FORMAT : url.UPDATE_FORMAT;
      const id = isAddMode ? "" : data.current?.procedureId;
      const result = await (isAddMode
        ? ApiServices.callServicePostWithBodyData(`${apiMethod}`, reqObj)
        : ApiServices.callServicePutWithBodyData(`${apiMethod}${id}`, reqObj));

      showToast(result);
      if (result?.meta?.success) {
        await getData();
        handleModal();
      }
    },
  });
  const { values, touched, errors, handleChange, handleSubmit, setFieldValue } =
    formik;

  useEffect(() => {
    const handleSetValues = (event) => {
      const formatOptions = (data) =>
        data?.map((item) => ({ label: item?.partName, value: item?.partId }));

      let obj = {
        procedure: {
          label: event?.detail?.procedureName,
          value: event?.detail?.procedureId,
          item: {
            _id: event?.detail?.procedureId,
            name: event?.detail?.procedureName,
            parts: formatOptions(event?.detail?.parts),
            defaultName: event?.detail?.procedureDefaultName,
          },
        },
        part: null,
        documents: [],
      };

      formik?.setValues(obj);
 
      setFormFields((prev) =>
        prev.map((field) =>
          field?.name === "part"
            ? {
                ...field,
                options:
                  formatOptions(
                    event?.detail?.parts?.filter(
                      (part) => part?.result?.length > 0
                    )
                  ) ?? [],
              }
            : field
        )
      );

      data.current = event?.detail;
    };

    window.addEventListener("editDynamicDocument", handleSetValues);
    return () =>
      window.removeEventListener("editDynamicDocument", handleSetValues);
  }, [formik]);

  const addItem = (field) => {
    const newArray = [
      ...(values?.[field] || []),
      { name: "", viewType: "List" },
    ];
    setFieldValue(field, newArray);
  };

  const removeItem = (field, index) => {
    const newArray = (values?.[field] || []).filter((_, i) => i !== index);
    setFieldValue(field, newArray);
  };

  return (
    <CustomDialog open={isOpen} onClose={() => handleModal()}>
      <DialogTitle>
        {modalMode === "edit" ? `Edit ${type}` : `Add ${type}`}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {formFields?.map((field) => {
              if (field?.hidden && field?.hidden(values)) {
                return null;
              }
              if (field?.type === "select") {
                return (
                  <Grid item xs={12} key={field?.name}>
                    <CustomSelect
                      name={field?.name}
                      label={field?.label}
                      value={values?.[field?.name]?.value || ""}
                      onChange={(event) => {
                        handleChange(event);
                        if (field?.extraOnChange) {
                          field?.extraOnChange(
                            event,
                            values,
                            setFieldValue,
                            field.options
                          );
                        }
                      }}
                      options={field?.options}
                      error={Boolean(
                        touched?.[field?.name] && errors?.[field?.name]
                      )}
                      helperText={
                        touched?.[field?.name] && errors?.[field?.name]
                      }
                      fullWidth
                    />
                  </Grid>
                );
              }

              if (field?.type === "array") {
                return (
                  <Grid item xs={12} key={field?.name}>
                    <InputLabel>{field?.label}</InputLabel>
                    {values?.[field?.name]?.length > 0 ? (
                      values?.[field?.name]?.map((item, index) => (
                        <Grid
                          container
                          key={index}
                          spacing={1}
                          alignItems="center"
                        >
                          {field?.fields?.map((subField) => {
                            const fieldName = `${field?.name}[${index}].${subField?.name}`;
                            switch (subField?.type) {
                              case "textField":
                                return (
                                  <Grid
                                    item
                                    xs={5}
                                    sx={{ marginTop: 2 }}
                                    key={subField?.name}
                                  >
                                    <CustomTextField
                                      name={fieldName}
                                      label={subField?.label}
                                      value={item[subField?.name] || ""}
                                      onChange={(e) =>
                                        setFieldValue(fieldName, e.target.value)
                                      }
                                      error={Boolean(
                                        touched?.[field?.name]?.[index]?.[
                                          subField?.name
                                        ] &&
                                          errors?.[field?.name]?.[index]?.[
                                            subField?.name
                                          ]
                                      )}
                                      helperText={
                                        touched?.[field?.name]?.[index]?.[
                                          subField?.name
                                        ] &&
                                        errors?.[field?.name]?.[index]?.[
                                          subField?.name
                                        ]
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                );

                              case "select":
                                return (
                                  <Grid
                                    item
                                    xs={5}
                                    sx={{ marginTop: 2 }}
                                    key={subField?.name}
                                  >
                                    <CustomSelect
                                      name={fieldName}
                                      label={subField?.label}
                                      value={item[subField?.name] || ""}
                                      onChange={(e) =>
                                        setFieldValue(fieldName, e.target.value)
                                      }
                                      options={subField?.options || []}
                                      error={Boolean(
                                        touched?.[field?.name]?.[index]?.[
                                          subField?.name
                                        ] &&
                                          errors?.[field?.name]?.[index]?.[
                                            subField?.name
                                          ]
                                      )}
                                      helperText={
                                        touched?.[field?.name]?.[index]?.[
                                          subField?.name
                                        ] &&
                                        errors?.[field?.name]?.[index]?.[
                                          subField?.name
                                        ]
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                );

                              // Add other field types like "checkbox", "date", etc. as needed

                              default:
                                return null; // If no matching type, return nothing
                            }
                          })}

                          {/* Delete Button */}
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => removeItem(field?.name, index)}
                              color="error"
                            >
                              <Delete />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <FormHelperText>{`No ${field?.name} added.`}</FormHelperText>
                    )}

                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<Add />}
                      onClick={() => addItem(field?.name)}
                      style={{ marginTop: 8 }}
                    >
                      Add Item
                    </Button>
                  </Grid>
                );
              }

              return null;
            })}
          </Grid>

          <Divider />
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleModal()}
            >
              Close
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </CustomDialog>
  );
};

export default withRouter(AddEditFormat);
