import React, { useState, useMemo } from "react";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  Drawer,
  Divider,
  Grid,
} from "@mui/material";
import CustomTooltip from "components/reusables/CustomTooltip";
import useConfig from "hooks/useConfig";
import { Add, Setting2 } from "iconsax-react";
import MainCard from "components/MainCard";
import withRouter from "components/withRouter";
import SimpleBar from "simplebar-react";
import { HEADER_HEIGHT } from "config";
// import ThemeMenuLayout from "./ThemeMenuLayout";
import ThemeMode from "./ThemeMode";

const Setting = (props) => {
  const { mode, menuOrientation } = useConfig();
  const [sideBaropen, setSideBarOpen] = useState(false);

  const themeMode = useMemo(() => <ThemeMode />, [mode]);

//   const themeMenuLayout = useMemo(() => <ThemeMenuLayout />, [menuOrientation]);
  const themeMenuLayout =null

  const handleSideBarToggle = () => {
    setSideBarOpen(!sideBaropen);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <CustomTooltip title="Setting">
        <IconButton
          color="secondary"
          aria-label="setting"
          onClick={handleSideBarToggle}
          size="large"
          sx={{
            color: "secondary.main",
            p: 1,
          }}
        >
          <Setting2 variant="Bulk" />
        </IconButton>
      </CustomTooltip>
      <Drawer
        sx={{
          zIndex: 2001,
        }}
        anchor="right"
        onClose={handleSideBarToggle}
        open={sideBaropen}
        PaperProps={{
          sx: {
            width: 350,
          },
        }}
      >
        {sideBaropen && (
          <MainCard content={false} border={false}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1.5}
              sx={{ p: 2.5 }}
            >
              <Typography variant="h5">Settings</Typography>
              <IconButton
                color="error"
                sx={{ p: 0 }}
                onClick={handleSideBarToggle}
              >
                <Add size={28} style={{ transform: "rotate(45deg)" }} />
              </IconButton>
            </Stack>
            <SimpleBar
              sx={{
                "& .simplebar-content": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <Box sx={{ p: 3, height: `calc(100vh - ${HEADER_HEIGHT}px)` }}>
                <Grid container spacing={2.5}>
                  {/* theme-mode */}
                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="subtitle1" color="text.primary">
                        Theme Mode
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Choose light or dark mode
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {themeMode}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  {/* theme-orientation */}
                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="subtitle1" color="text.primary">
                        Menu Orientation
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Choose Vertical or Horizontal Menu Orientation
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {themeMenuLayout}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Box>
            </SimpleBar>
          </MainCard>
        )}
      </Drawer>
    </Box>
  );
};

export default withRouter(Setting);
