import PropTypes from "prop-types";
import { Box } from "@mui/material";
import getColors from "utils/getColors";
import withRouter from "components/withRouter";

const Dot = (props) => {
  const { color, size, variant, sx, componentDiv, theme } = props;
  const colors = getColors(theme, color || "primary");
  const { main } = colors;

  return (
    <Box
      component={componentDiv ? "div" : "span"}
      sx={{
        width: size || 8,
        height: size || 8,
        borderRadius: "50%",
        bgcolor: variant === "outlined" ? "" : main,
        ...(variant === "outlined" && {
          border: `1px solid ${main}`,
        }),
        ...sx,
      }}
    />
  );
};

Dot.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.string,
  componentDiv: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default withRouter(Dot);
