import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { allPagesAndTypes } from "./config";

// Define lazy-loaded components
const Test = Loadable(lazy(() => import("./Test")));
const Test2 = Loadable(lazy(() => import("./Test2")));

export const getComp = (view, arg) => {
  if (view === "users" || view ==="teachers") {
    return <Test {...arg} />;
  } else if (view === "teachers") {
    return <Test2 {...arg} />;
  }
};

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const TestRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("test", null, [
      createRoute(
        "test",
        getComp(
          "users",
          allPagesAndTypes.find((item) => item.path === "/test")
        )
      ),
      createRoute(
        "test2",
        getComp(
          "teachers",
          allPagesAndTypes.find((item) => item.path === "/test2")
        )
      ),
    ]),
  ]
);

export default TestRoutes;
