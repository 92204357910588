import React, { useEffect, useRef } from "react";
import {
  Grid,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFormik } from "formik";
import CustomDialog from "components/reusables/CustomDialog";
import CustomSelect from "components/atom/CustomSelect";
import { showToast } from "services/utils";
import * as url from "../utils/urlHelper";
import withRouter from "components/withRouter";
import CustomTextField from "components/atom/CustomTextField";

const AddEditDocument = (props) => {
  const {
    isOpen,
    setIsOpen,
    modalMode = "add",
    setModalMode,
    module,
    type,
    getData,
    ApiServices,
    validationSchema,
    moduleType,
    initialValues,
    formFields,
  } = props;

  const data = useRef(null);

  const handleModal = (mode = "add", item = null) => {
    setIsOpen(!isOpen);
    setModalMode(mode);
    if (mode === "add") {
      formik.resetForm();
    } else if (mode === "edit" && item) {
      formik.setValues(item);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const isAddMode = modalMode === "add";
      let reqObj = {
        ...values,
        module,
        ...(isAddMode && { defaultName: values?.name }),
      };
      const apiMethod = isAddMode ? url.CREATE : url.UPDATE_SPECIFIC;
      const id = isAddMode ? "" : values?._id;
      const result = await (isAddMode
        ? ApiServices.callServicePostWithBodyData(`${apiMethod}`, reqObj)
        : ApiServices.callServicePutWithBodyData(`${apiMethod}${id}`, reqObj));

      showToast(result);
      if (result?.meta?.success) {
        await getData();
        handleModal();
      }
    },
  });

  const { values, touched, errors, handleChange, handleSubmit, setFieldValue } =
    formik;

  useEffect(() => {
    const handleSetValues = (event) => {
      formik.setValues(event?.detail);
      data.current = event?.detail;
    };

    window.addEventListener("editDynamicDocument", handleSetValues);
    return () =>
      window.removeEventListener("editDynamicDocument", handleSetValues);
  }, [formik]);

  // Function to handle adding an empty field to the array
  const addItem = (field) => {
    const newArray = [...(values?.[field] || []), { name: "" }];
    setFieldValue(field, newArray);
  };

  // Function to handle removing a field from the array
  const removeItem = (field, index) => {
    const newArray = (values?.[field] || []).filter((_, i) => i !== index);
    setFieldValue(field, newArray);
  };

  return (
    <CustomDialog open={isOpen} onClose={() => handleModal()}>
      <DialogTitle>
        {modalMode === "edit" ? `Edit ${type}` : `Add ${type}`}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {formFields?.map((field) => {
              if (field?.hidden) {
                // Check if the field is hidden based on the value of the hiddenField (if specified)
                if (
                  field?.hiddenField &&
                  field?.hidden(values?.[field?.hiddenField])
                ) {
                  return null;
                }

                // Check if the field is hidden based on its own value
                if (field?.hidden(values?.[field?.name])) {
                  return null;
                }
              }

              if (field?.type === "textField") {
                return (
                  <Grid item xs={12} key={field?.name}>
                    <CustomTextField
                      name={field?.name}
                      label={field?.label}
                      value={values?.[field?.name] || field?.initialValue}
                      onChange={handleChange}
                      error={Boolean(
                        touched?.[field?.name] && errors?.[field?.name]
                      )}
                      helperText={
                        touched?.[field?.name] && errors?.[field?.name]
                      }
                    />
                  </Grid>
                );
              }

              if (field?.type === "select") {
                return (
                  <Grid item xs={12} key={field?.name}>
                    <CustomSelect
                      name={field?.name}
                      label={field?.label}
                      value={values?.[field?.name] || field?.initialValue}
                      onChange={(event) => {
                        // Call Formik's handleChange with the event
                        handleChange(event);

                        // If there's an extraOnChange function, call it with necessary parameters
                        if (field?.extraOnChange) {
                          // Assuming extraOnChange takes the event, values, and setFieldValue as arguments
                          field?.extraOnChange(event, values, setFieldValue);
                        }

                        // Handle special case for boolean values
                        const value = event.target.value === "true"; // Converts string to boolean (true or false)
                        setFieldValue(field?.name, value);
                      }}
                      options={field?.options}
                      error={Boolean(
                        touched?.[field?.name] && errors?.[field?.name]
                      )}
                      helperText={
                        touched?.[field?.name] && errors?.[field?.name]
                      }
                      fullWidth
                    />
                  </Grid>
                );
              }

              // Manually handle array field
              if (field?.type === "array") {
                return (
                  <Grid item xs={12} key={field?.name}>
                    <InputLabel>{field?.label}</InputLabel>
                    {values?.[field?.name]?.length > 0 ? (
                      values?.[field?.name]?.map((item, index) => (
                        <Grid
                          container
                          key={index}
                          spacing={1}
                          alignItems="center"
                        >
                          <Grid item xs={10} sx={{ marginTop: 2 }}>
                            <CustomTextField
                              name={`${field?.name}.${index}.name`}
                              label={field?.label}
                              value={item.name || ""}
                              onChange={(e) =>
                                setFieldValue(
                                  `${field?.name}[${index}].name`,
                                  e.target.value
                                )
                              }
                              error={Boolean(
                                touched?.[field?.name]?.[index]?.name &&
                                  errors?.[field?.name]?.[index]?.name
                              )}
                              helperText={
                                touched?.[field?.name]?.[index]?.name &&
                                errors?.[field?.name]?.[index]?.name
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => removeItem(field?.name, index)}
                              color="error"
                            >
                              <Delete />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <FormHelperText>{`No ${field?.name}s added.`}</FormHelperText>
                    )}
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<Add />}
                      onClick={() => addItem(field?.name)}
                      style={{ marginTop: 8 }}
                    >
                      Add Item
                    </Button>
                  </Grid>
                );
              }

              return null;
            })}
          </Grid>

          <Divider />
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleModal()}
            >
              Close
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </CustomDialog>
  );
};

export default withRouter(AddEditDocument);
