import { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import { useSelector } from "store";
import useConfig from "hooks/useConfig";
import { HORIZONTAL_MAX_ITEM } from "config";
import { MenuOrientation } from "config";
import {
  Home3,
  Profile2User,
  Calendar1,
  Award,
  UserTag,
  TableDocument,
  DocumentText,
  Messages2,
  Record,
  MenuBoard,
  Note,
  Judge,
  Box1,
  Grid3,
  More,
} from "iconsax-react";
import HistoryIcon from "@mui/icons-material/History";
import ReportIcon from "@mui/icons-material/Report";
import { ConvertToKebabCase, getItem } from "services/utils";
import { FeedbackTwoTone } from "@mui/icons-material";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import withRouter from "components/withRouter";
import { FormatConstants, routeConstants } from "services/keyConstants";

// icons
const icons = {
  dashboard: Home3,
  client: Profile2User,
  standard: Award,
  roles: UserTag,
  Record: Record,
  ReportIcon: ReportIcon,
  MenuBoard: MenuBoard,
  table: TableDocument,
  calendar: Calendar1,
  history: HistoryIcon,
  Judge: Judge,
  documentText: DocumentText,
  Note: Note,
  FeedbackTwoTone: FeedbackTwoTone,
  Messages2: Messages2,
  Box: Box1,
  Grid: Grid3,
  finish: CheckCircleOutlineIcon,
  general: AutoAwesomeMosaicIcon,
  more: More,
};

const Navigation = (props) => {
  const { theme, Constants } = props;

  const downLG = useMediaQuery(theme.breakpoints.down("lg"));

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);

  const [selectedItems, setSelectedItems] = useState("");
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [menuItems, setMenuItems] = useState({ items: [] });

  // Function to generate contents based on pathname and userData
  const generateContents = (userData) => {
    let baseContents;
    if (userData?.externalUser) {
      baseContents = {
        id: "contents",
        title: "",
        type: "group",
        children: [],
      };
    } else {
      baseContents = {
        id: "contents",
        title: "Contents",
        type: "group",
        children: [
          {
            id: "Users",
            title: "Users",
            type: "item",
            url: "/users",
            icon: icons.client,
          },
          {
            id: "Standards",
            title: "Standards",
            type: "item",
            url: "/standards/standard-list",
            icon: icons.standard,
          },
          {
            id: "Dynamic Document",
            title: "Dynamic Document",
            type: "item",
            url: "/dynamic-document/manage",
            icon: icons.table,
          },
          {
            id: "Master Table",
            title: "Master Table",
            type: "item",
            url: "/master-table",
            icon: icons.table,
          },
          {
            id: "Products",
            title: "Master Products",
            type: "item",
            url: "/product/list",
            icon: icons.Box,
          },
          {
            id: "Finished Product Register",
            title: "Finished Product Register",
            type: "item",
            url: "/finished-product-register/view",
            icon: icons.finish,
          },
          {
            id: "Permissions",
            title: "Permissions",
            type: "item",
            url: "/permission",
            icon: icons.table,
          },
          {
            id: "comptency-matrix",
            title: "Competency Matrix",
            type: "item",
            url: `/${routeConstants.CAT}/view/${ConvertToKebabCase(FormatConstants.CAT.COMPETENCY_MATRIX)}`,
            icon: icons.Box,
          },
          {
            id: "training-calender",
            title: "Training Calender",
            type: "item",
            url: `/${routeConstants.CAT}/view/${ConvertToKebabCase(FormatConstants.CAT.TRAINING_CALENDAR)}`,
            icon: icons.calendar,
          },
          {
            id: "Calendar",
            title: "Calendar",
            type: "item",
            url: "/calendar",
            icon: icons.calendar,
          },
          {
            id: "General",
            title: "General",
            type: "collapse",
            icon: icons.general,
            children: [

              {
                id: "Change Note Report",
                title: "Change Note Report",
                type: "item",
                url: "/changeNoteHistory",
              },

              {
                id: "Induction Training Records",
                title: "Induction Training Records",
                type: "item",
                url: `/${routeConstants.CAT}/saved-inductionTrainerData`,
              },

              {
                id: "Requisition for Man-Power",
                title: "Requisition for Man-Power",
                type: "item",
                url: `/${routeConstants.CAT}/saved-requition-manpower`,
              },
            ],
          },
        ],
      };
    }

    return baseContents;
  };

  let userData = getItem(Constants.KEY_USER_DATA);
  let contents = generateContents(userData);

  useEffect(() => {
    let data = {
      items: [...menuItem.items, contents],
    };
    setMenuItems(data);
    // eslint-disable-next-line
  }, [menuItem, location?.pathname]);

  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems?.items?.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < menuItems?.items?.length) {
    lastItemId = menuItems?.items[lastItem - 1]?.id;
    lastItemIndex = lastItem - 1;
    remItems = menuItems?.items
      .slice(lastItem - 1, menuItems?.items?.length)
      .map((item) => ({
        title: item?.title,
        elements: item?.children,
        icon: item?.icon,
      }));
  }

  const navGroups = menuItems?.items
    ?.slice(0, lastItemIndex + 1)
    .map((item) => {
      switch (item?.type) {
        case "group":
          return (
            <NavGroup
              key={item?.id}
              setSelectedItems={setSelectedItems}
              setSelectedLevel={setSelectedLevel}
              selectedLevel={selectedLevel}
              selectedItems={selectedItems}
              lastItem={lastItem}
              remItems={remItems}
              lastItemId={lastItemId}
              item={item}
            />
          );
        default:
          return (
            <Typography
              key={item?.id}
              variant="h6"
              color="error"
              align="center"
            >
              Fix - Navigation Group
            </Typography>
          );
      }
    });
  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        "& > ul:first-of-type": { mt: 0 },
        display: isHorizontal ? { xs: "block", lg: "flex" } : "block",
      }}
    >
      {navGroups}
    </Box>
  );
};

export default withRouter(Navigation);
