import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import { MenuOrientation } from "config";
import Logo from "components/logo";
import { DRAWER_WIDTH, HEADER_HEIGHT } from "config";
import useConfig from "hooks/useConfig";
import companyDummuyLogo from "assets/images/company-dummy-logo.jpg";
import withRouter from "components/withRouter";

const DrawerHeader = (props) => {
  const { open, userData: initialUserData, theme } = props;
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));
  const { menuOrientation } = useConfig();
  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downLG;
  const [userData, setUserData] = useState(initialUserData);

  // Event handler function for companyLogoUpdated
  const handleCompanyLogoUpdate = (event) => {
    const { companyLogo } = event?.detail;
    setUserData((prevData) => ({
      ...prevData,
      orgInfo: {
        ...prevData.orgInfo,
        companyLogo,
      },
    }));
  };

  // UseEffect to add and clean up event listeners
  useEffect(() => {
    window.addEventListener("companyLogoUpdated", handleCompanyLogoUpdate);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("companyLogoUpdated", handleCompanyLogoUpdate);
    };
  }, []);

  return (
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      sx={{
        minHeight: isHorizontal ? "unset" : HEADER_HEIGHT,
        width: isHorizontal ? { xs: "100%", lg: DRAWER_WIDTH + 50 } : "inherit",
        paddingTop: isHorizontal ? { xs: "10px", lg: "0" } : "8px",
        paddingBottom: isHorizontal ? { xs: "18px", lg: "0" } : "8px",
        paddingLeft: isHorizontal ? { xs: "24px", lg: "0" } : open ? "0px" : 0,
      }}
    >
      <Logo
        images={
          userData?.orgInfo?.companyLogo?.length > 0
            ? props?.service?.IMG_URL + userData?.orgInfo?.companyLogo
            : companyDummuyLogo
        }
        isIcon={!open}
        sx={{
          width: isHorizontal
            ? { xs: "100%", lg: DRAWER_WIDTH + 50 }
            : "inherit",
          height: "50px",
          padding: "0 5px",
        }}
      />
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default withRouter(DrawerHeader);
