import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import PreventiveMaintenanceList from "../PreventiveMaintenanceList";
import PreventiveYearList from "../PreventiveYearList";
import { routeConstants } from "services/keyConstants";
// Define lazy-loaded components
const PreventiveMachinePlan = Loadable(
  lazy(() => import("../PreventiveMachinePlan"))
);
const PreventiveMaintenanceReport = Loadable(
  lazy(() => import("../PreventiveMaintenanceReport"))
);
const MasterListOfMachine = Loadable(
  lazy(() => import("../MasterListOfMachine"))
);
const MachineBreakDown = Loadable(lazy(() => import("../MachineBreakDown")));
const MachineBreakDownList = Loadable(
  lazy(() => import("../MachineBreakDownList"))
);
const PreventiveMaintananceReportList = Loadable(
  lazy(() => import("../PreventiveMaintananceReportList"))
);
// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const PreventiveMaintenanceRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.PREVENTIVE_MAINTAINCE}`, null, [
      createRoute("master-list-machine", <MasterListOfMachine />),
      createRoute("maintenance-plan", <PreventiveMachinePlan />),
      createRoute("machine-break-down", <MachineBreakDown />),
      createRoute("maintenance-report", <PreventiveMaintenanceReport />),
      createRoute(
        "maintenance-report-list/:year",
        <PreventiveMaintananceReportList />,
      ),
      createRoute("machine-break-downList/:year", <MachineBreakDownList />),
      createRoute("preventive-maintenance-list/:year", <PreventiveMaintenanceList />),
      createRoute("year-list", <PreventiveYearList />),
    ]),
  ]
);

export default PreventiveMaintenanceRoutes;
