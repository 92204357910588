import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";

// Define lazy-loaded components
const Feedback = Loadable(lazy(() => import("../Feedback")));
const FeedbackById = Loadable(lazy(() => import("../FeedbackById")));
const List = Loadable(lazy(() => import("../List")));
const YearList = Loadable(lazy(() => import("../YearList")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const FeedbackRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.FEEDBACK, null, [
      createRoute("view/:year", <Feedback />),
      createRoute(":id/:subId?", <FeedbackById />),
      createRoute("list/:year", <List />),
      createRoute("year-list", <YearList />)
    ]),
  ]
);

export default FeedbackRoute;
