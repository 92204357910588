import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import FormatList from "../FormatList";
import FormatListByYear from "../FormatListByYear";
import FormatListByMonth from "../FormatListByMonth";
import DocumentList from "../DocumentList";

const DocumentByDates = Loadable(lazy(() => import("../DocumentByDates")));
// Define lazy-loaded components
const AddFormat = Loadable(
  lazy(() => import("../AddFormat"))
);
const ViewById = Loadable(
  lazy(() => import("../ViewById"))
);
// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const FormatRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("format", null, [
      createRoute("list", <FormatList />), // Replace with your actual component
      createRoute("by-year/:moduleName", <FormatListByYear />), // Replace with your actual component
      createRoute("by-month/:moduleName/:year", <FormatListByMonth />), // Replace with your actual component
      createRoute("by-month/:moduleName/:year/:month", <DocumentList />), // Replace with your actual component
      createRoute(
        "by-date/:moduleName/:year/:month/:documentName",
        <DocumentByDates />
      ), // Replace with your actual component

      createRoute("add", <AddFormat />),
      createRoute(":id", <ViewById />),
    ]),
  ]
);

export default FormatRoute;
