import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

const BatchManufacturingRecord = Loadable(
  lazy(() => import("../BatchManufacturingRecord"))
);
const ViewProduct = Loadable(lazy(() => import("../ViewProduct")));
const SubProductDetails = Loadable(lazy(() => import("../SubProductDetails")));
const RequistionSlip = Loadable(lazy(() => import("../RequisitionSlip")));
const ProductionPlanning = Loadable(
  lazy(() => import("../ProductionPlanning"))
);
const BatchManufacturingRecordList = Loadable(
  lazy(() => import("../BatchManufacturingRecordList"))
);
const BatchReleaseChecklist = Loadable(
  lazy(() => import("../BatchReleaseChecklist"))
);

const IssueSlipList = Loadable(lazy(() => import("../IssueSlipList")));
const IssueMonthList = Loadable(lazy(() => import("../IssueMonthList")));
const RequisitionSlipList = Loadable(
  lazy(() => import("../RequisitionSlipList"))
);
const RequisitionMonthList = Loadable(
  lazy(() => import("../RequisitionMonthList"))
);
const IssueSlip = Loadable(lazy(() => import("../IssueSlip")));
const ProductionList = Loadable(lazy(() => import("../ProductionList")));
const StockRegister = Loadable(lazy(() => import("../StockRegister")));
const MaterialRequisitionSlip = Loadable(
  lazy(() => import("../MaterialRequisitionSlip"))
);
const BMRMonthList = Loadable(lazy(() => import("../BMRMonthList")));
const StockRegisterMonthList = Loadable(
  lazy(() => import("../StockRegisterMonthList"))
);
const StockRegisterList = Loadable(lazy(() => import("../StockRegisterList")));
const PorductionPlanningList = Loadable(
  lazy(() => import("../PorductionPlanningList"))
);
const PlanningMonthData = Loadable(lazy(() => import("../PlanningMonthData")));
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const ProdRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("production", null, [
      createRoute("list", <ProductionList />),
      createRoute("requisition-slip", <RequistionSlip />),
      createRoute("planning", <ProductionPlanning />),
      createRoute("issue-slip", <IssueSlip />),
      createRoute("stock-register", <StockRegister />),
      createRoute("batch-manufacturing-record", <BatchManufacturingRecord />),
      createRoute("stock-register-list", <StockRegisterList />),
      createRoute(
        "batch-manufacturing-record-list",
        <BatchManufacturingRecordList />
      ),
      createRoute("bmr-month-data", <BMRMonthList />),
      createRoute("stock-register-month-data", <StockRegisterMonthList />),
      createRoute("planning-list", <PorductionPlanningList />),
      createRoute("planning-month-data", <PlanningMonthData />),
      createRoute("batch-release-checklist", <BatchReleaseChecklist />),
      createRoute("material-requisition-slip", <MaterialRequisitionSlip />),
      createRoute("issue-slip-list", <IssueSlipList />),
      createRoute("issue-month-list", <IssueMonthList />),
      createRoute("requisition-slip-list", <RequisitionSlipList />),
      createRoute("requisition-month-list", <RequisitionMonthList />),
      createRoute("view-product/:id", <ViewProduct />),
      // createRoute("sub-product/:id", <SubProductDetails />),
      createRoute("sub-product", <SubProductDetails />),
    ]),
  ]
);

export default ProdRoutes;
