import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";

const CWRB = Loadable(lazy(() => import("../CWRB")));
const CWRBList = Loadable(lazy(() => import("../CWRBList")));
const CommunicationToRegBody = Loadable(
  lazy(() => import("../CommunicationToRegBody"))
);
const ChangeManagementYearData = Loadable(
  lazy(() => import("../ChangeManagementYearData"))
);
const CustomerComplaintYearData = Loadable(
  lazy(() => import("../CustomerComplaintYearData"))
);
const AdvisoryNotice = Loadable(lazy(() => import("../AdvisoryNotice")));
const AdvisoryNoticeList = Loadable(
  lazy(() => import("../AdvisoryNoticeList"))
);
const AmendmentsInTechnicalFile = Loadable(
  lazy(() => import("../AmendmentsInTechnicalFile"))
);
const AmendmentsInTechnicalFileYearData = Loadable(
  lazy(() => import("../AmendmentsInTechnicalFileYearData"))
);
const ChangeManagement = Loadable(lazy(() => import("../ChangeManagement")));
const CustomerComplaint = Loadable(lazy(() => import("../CustomerComplaint")));
const DesignModification = Loadable(
  lazy(() => import("../DesignModification"))
);
const DesignModificationYearData = Loadable(
  lazy(() => import("../DesignModificationYearData"))
);
const List = Loadable(lazy(() => import("../List")));
const YearList = Loadable(lazy(() => import("../YearList")));

const createRoute = (path, element, children) => ({ path, element, children });

const CWRBRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.COMMUNICATION_WITH_REGULATORY_BODIES}`, null, [
      createRoute("communication-with-regulatory-body", <CWRB />),
      createRoute("cwrb-list", <CWRBList />),
      createRoute("communicationToRegBody", <CommunicationToRegBody />),
      createRoute("list/:year", <List />),
      createRoute("year-list", <YearList />),
      createRoute("advisory-notice", <AdvisoryNotice />),
      createRoute("customer-complaint-year", <CustomerComplaintYearData />),
      createRoute("advisory-notice-list", <AdvisoryNoticeList />),
      createRoute("amendments-technical-file", <AmendmentsInTechnicalFile />),
      createRoute(
        "amendments-technical-file-year",
        <AmendmentsInTechnicalFileYearData />
      ),
      createRoute("change-management", <ChangeManagement />),
      createRoute("change-management-year", <ChangeManagementYearData />),
      createRoute("customer-complaint", <CustomerComplaint />),
      createRoute("design-modification", <DesignModification />),
      createRoute("design-modification-year", <DesignModificationYearData />),
    ]),
  ]
);

export default CWRBRoutes;
