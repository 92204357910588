const baseUrl = "/master-doc-module";

export const ADD_NEW_PROCEDURE = `${baseUrl}`;
export const GET_ALL = `${baseUrl}/all`;

export const CREATE = `${baseUrl}/`;
export const CREATE_FORMAT = `${baseUrl}/create-format`;

export const UPDATE = `${baseUrl}/`;

export const UPDATE_SPECIFIC = `${baseUrl}/specific/`;
export const UPDATE_FORMAT = `${baseUrl}/specific-format/`;
export const DELETE = `${baseUrl}/`;

// for quality-manual & procedure

export const GET_DATA_BY_DEFAULTNAME = `${baseUrl}/by-default-name`;

export const GET_BY_FOLDER = `${baseUrl}/get-folder/`;

export const GET_BY_MASTER_ID = `${baseUrl}/by-masterId/`;

export const GET_BY_OBSOLETE = `${baseUrl}/get-obsolete/`;
export const GET_BY_YEAR = `${baseUrl}/get-by-year/`;
export const GET_BY_YEAR_MASTER_ID_AND_DOCUMENT_STATE = `${baseUrl}/get-obsolete/`;
export const GET_DOCUMENT_NUMBERS = `${baseUrl}/get-all-by-document-numbers`;
