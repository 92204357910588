import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

const ProductList = Loadable(lazy(() => import("../ProductList")));
const AddChildrenProduct = Loadable(lazy(() => import("../AddChildrenProduct")));
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const ProductRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("product", null, [
      createRoute("list", <ProductList />),
      createRoute("add-children-product/:id", <AddChildrenProduct />),
    ]),
  ]
);

export default ProductRoutes;
