import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

const TemperatureHumidityTable = Loadable(
  lazy(() => import("../TemperatureHumidityTable"))
);
const AppronCleaning = Loadable(lazy(() => import("../AppronCleaning")));
const FumigationCycle = Loadable(lazy(() => import("../FumigationCycle")));
const PersonalHygeneRecord = Loadable(
  lazy(() => import("../PersonalHygeneRecord"))
);
const WorkEnvironment = Loadable(lazy(() => import("../WorkEnvironment")));
const SterileList = Loadable(lazy(() => import("../SterileList")));
const SterileFolders = Loadable(lazy(() => import("../SterileFolders")));
const FumigationTable = Loadable(lazy(() => import("../FumigationTable")));
const AppronTable = Loadable(lazy(() => import("../AppronTable")));
const HygieneTable = Loadable(lazy(() => import("../HygieneTable")));
const TemperatureTable = Loadable(lazy(() => import("../TemperatureTable")));
const CleanDoneFor = Loadable(lazy(() => import("../CleanDoneFor")));
const CleaningTable = Loadable(lazy(() => import("../CleaningTable")));
const StatusCheck = Loadable(lazy(() => import("../StatusCheck")));
const CleaningRegister = Loadable(lazy(() => import("../CleaningRegister")));
const WorkEnvironmentTables = Loadable(
  lazy(() => import("../WorkEnvironmentTables"))
);
const FumigationDoneFor = Loadable(lazy(() => import("../FumigationDoneFor")));
const VersionList = Loadable(lazy(() => import("../VersionList")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

const WERoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("work-environment", null, [
      createRoute("version-list/:id", <VersionList />),
      createRoute("cleaning-register", <CleaningRegister />),
      createRoute("temperature-table", <TemperatureHumidityTable />),
      createRoute("Appron-cleaning", <AppronCleaning />),
      createRoute("fumigation-cycle", <FumigationCycle />),
      createRoute("personal-hygene-record", <PersonalHygeneRecord />),
      createRoute("environment", <WorkEnvironment />),
      createRoute("sterile-check", <SterileList />),
      createRoute("sterile-folder", <SterileFolders />),
      createRoute("fumigation-table", <FumigationTable />),
      createRoute("appron-table", <AppronTable />),
      createRoute("hygine-table", <HygieneTable />),
      createRoute("temperature-data-table", <TemperatureTable />),
      createRoute("clean-done-for", <CleanDoneFor />),
      createRoute("cleaning-table", <CleaningTable />),
      createRoute("status-check", <StatusCheck />),
      createRoute("we-tables/:id", <WorkEnvironmentTables />),
      createRoute("fumigation-done-for", <FumigationDoneFor />),
    ]),
  ]
);

export default WERoutes;
