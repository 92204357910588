import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
// render - pages
const ProcedureDetails = Loadable(lazy(() => import("../ProcedureDetails")));
const AddEditProcedure = Loadable(lazy(() => import("../AddEditProcedure")));
const ProcedureDashboard = Loadable(
  lazy(() => import("../ProcedureDashboard"))
);
const VersionList = Loadable(lazy(() => import("../VersionList")));
const List = Loadable(lazy(() => import("../../molecules/List")));
const Folder = Loadable(lazy(() => import("../../molecules/Folder")));
const Detail = Loadable(lazy(() => import("../../molecules/Detail")));
const YearList = Loadable(lazy(() => import("../../molecules/YearList")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

const ProcedureRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("standards", null, [
      createRoute("procedure-details/:documentId", <ProcedureDetails />),
      createRoute("addEdit-procedure", <AddEditProcedure />),
      createRoute("procedure-dashboard", <ProcedureDashboard />),
      createRoute("procedure-version-list", <VersionList />),
      createRoute(
        "procedure-list",
        <List
          moduleType="procedure"
          limit={30}
          name="Procedure"
          navigateUrl="procedure-details"
        />
      ),
      // get by year
      createRoute(
        "procedure-detail-year/:documentId/:type?",
        <YearList
          moduleType="procedure"
          name="Procedure"
          navigateUrl="procedure-type"
        />
      ),
      // for document state folders
      createRoute(
        "procedure-type/:documentId",
        <Folder
          moduleType="procedure"
          name="Procedure"
          detailUrl="procedure-type"
          yearUrl="procedure-detail-year"
        />
      ),
      // detail by id
      createRoute(
        "procedure-type/:documentId/:type?/:year?",
        <Detail moduleType="procedure" name="Procedure" />
      ),
    ]),
  ]
);

export default ProcedureRoutes;
