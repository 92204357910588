import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import { lazy } from "react";
import { routeConstants } from "services/keyConstants";

const List = Loadable(lazy(() => import("../List")));
const AddEdit = Loadable(lazy(() => import("../molecules/AddEdit")));

const createRoute = (path, element, children) => ({ path, element, children });

const ChangeNoteRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.CHANGE_NOTE, null, [
      createRoute("change-note-history", <List />),
      createRoute("manage", <AddEdit />),
    ]),
  ]
);

export default ChangeNoteRoute;
