import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";

const GenerateIncoming = Loadable(lazy(() => import("../GenerateIncoming")));
const GenerateFinal = Loadable(lazy(() => import("../GenerateFinal")));
const MamGeneratePlan = Loadable(lazy(() => import("../MamGeneratePlan")));
const InprocessPlanForm = Loadable(lazy(() => import("../InprocessPlanForm")));
const FinalPlanForm = Loadable(lazy(() => import("../FinalPlanForm")));
const InProcessTable = Loadable(lazy(() => import("../InProcessTable")));
const ProductDetails = Loadable(lazy(() => import("../ProductDetails")));
const FinalInspectionHistory = Loadable(
  lazy(() => import("../FinalInspectionHistory"))
);

const YearList = Loadable(lazy(() => import("../YearList")));
const List = Loadable(lazy(() => import("../List")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const MAMRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.MAM_OF_PRODUCT}`, null, [
      createRoute("mam-generate-plan/:year", <MamGeneratePlan />),
      createRoute("year-list", <YearList />),
      createRoute("list/:year", <List />),
      createRoute("incoming", <GenerateIncoming />),
      createRoute("final", <GenerateFinal />),
      createRoute("in-process-history/:year", <InProcessTable />),
      createRoute("final-inspection-history/:year", <FinalInspectionHistory />),
      createRoute("product-details/:id/:productName", <ProductDetails />),
      createRoute(
        "inprocess-plan-form/:id/:processId/:mainRecordId/:historyId",
        <InprocessPlanForm />
      ),
      createRoute(
        "final-plan-form/:id/:mainRecordId/:historyId",
        <FinalPlanForm />
      ),
    ]),
  ]
);

export default MAMRoutes;
