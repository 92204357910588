import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import CODFormats from "../CODFormats";
import MasterListOfDoc from "../MasterListOfDoc";
import ExternalListOfDoc from "../ExternalListOfDoc";
import CODChildFormats from "../CODChildFormats";

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const CODRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("control-of-document", null, [
      createRoute("codFormat", <CODFormats />),
      createRoute("masterList", <MasterListOfDoc />),
      createRoute("externalList", <ExternalListOfDoc />),
      createRoute("codChildformat", <CODChildFormats />),
    ]),
  ]
);

export default CODRoutes;
