import { ApiServices } from "./apiServices";
import * as url from "services/url_helper";
import * as organogramUrl from "pages/standards/QualityManual/Organogram/utils/urlHelper";
import { getItem } from "./utils";
import { Constants } from "./keyConstants";

// Old Service

export const filterUsers = (
  content,
  key,
  value,
  results = {},
  indexObj = { index: 0 }
) => {
  for (let item of content) {
    if (
      key?.startsWith("userData.") &&
      item?.userData?.[key?.replace("userData.", "")] === value
    ) {
      results[indexObj.index++] = item;
    } else if (
      (key === "designation" && item?.designation === value) ||
      (key === "extraDesignation" &&
        value === "MR" &&
        item?.extraDesignation?.designation === value) ||
      (key === "department" &&
        !item?.userData &&
        !item?.title?.includes[("Managing Director", "Director")])
    ) {
      results[indexObj.index++] = item;
    }

    if (item?.children?.length) {
      TreeData(item.children, key, value, results, indexObj);
    }
  }
  return results;
};

// for getting first head of department
export const getChildAtIndex = (data, index = 0) => {
  // Check if data and the nested properties exist
  const designation = data?.defaultDesignation?.[0];
  const child = designation?.children?.[index];

  // Return null if no child is found at the index
  if (!child) return null;

  // Return the child along with department details
  return {
    ...child,
    departmentDetails: {
      defaultDesignation: designation?.defaultDesignation,
      title: designation?.title,
      designation: designation?.designation,
    },
  };
};

// for filter department user by department object
export const extractUserDataByDepartment = (data, userIdOnly = false) => {
  const userDataList = [];

  // Arrow function to recursively traverse the data
  const traverse = (obj) => {
    // Use optional chaining to safely access 'children' property
    const children = obj?.children;

    if (children && Array.isArray(children)) {
      children.forEach((child) => {
        const userData = child?.userData;

        if (userData && userData?.userId !== "") {
          userDataList?.push({
            userId: userData?.userId,
            name: userData?.name,
            responsibilities: userData?.responsibilities,
            designation: child?.designation,
          });
        }

        // Recursively traverse the child objects if they have children
        traverse(child);
      });
    }
  };

  // Start the traversal from the root object
  traverse(data?.defaultDesignation?.[0]);

  const userIds = userDataList
    ?.filter((user) => user?.userId)
    .map((user) => user.userId);

  return userIdOnly === true ? userIds : userDataList;
};

// for filter department user by department object
export const extractUserDataByResponsibility = (
  data,
  isMultiple = false,
  userIdOnly = false
) => {
  const userDataList = [];
  // Arrow function to recursively traverse the data
  if (isMultiple) {
    const traverse = (obj) => {
      // Use optional chaining to safely access 'children' property
      const children = obj;
      const userData = children?.userData;
      if (userData && userData?.userId !== "") {
        userDataList?.push({
          userId: userData?.userId,
          name: userData?.name,
          responsibilities: userData?.responsibilities,
          designation: userData?.designation,
          defaultResponsibilities: children?.responsibilities,
          extraDesignation: children?.extraDesignation,
        });
      }
    };
    // Start the traversal from the root object
    traverse(data?.designationResult?.[0]);
  } else {
    const traverse = (obj) => {
      Object.keys(obj).forEach((key) => {
        const item = obj[key];
        const userData = item?.userData;

        if (userData && userData?.userId !== "") {
          userDataList.push({
            userId: userData?.userId,
            name: userData?.name,
            responsibilities: userData?.responsibilities || [],
            designation: item?.designation || "Undefined Designation",
            defaultResponsibilities: item?.responsibilities || [],
            extraDesignation: item?.extraDesignation,
          });
        }
      });
    };

    // Start the traversal from the root object
    traverse(data?.designationResult);
  }
  const userIds = userDataList
    ?.filter((user) => user?.userId)
    .map((user) => user.userId);

  return userIdOnly === true
    ? userIds
    : isMultiple
    ? userDataList?.[0]
    : userDataList;
};

// for get head data by department title
export const getUsersDataByDepartment = async (
  title,
  isHead = false,
  topMgmt = ""
) => {
  if (title === "MR" && topMgmt === "MR") {
    let reqObj = {
      orgId: getItem(Constants.KEY_USER_DATA)?.orgId,
      designation: "MR",
    };
    let uri = url.FILTER_USER_DATA_BY_ORGANOGRAM;
    let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    return result?.meta?.success
      ? [result?.data?.designationResult?.[0]?.userData] ?? null
      : null;
  }
  try {
    let reqObj = {
      orgId: getItem(Constants.KEY_USER_DATA)?.orgId,
      // designation: "HR Manager",
      defaultDesignation: title,
      ...(topMgmt !== "" && { title: topMgmt }),
    };
    let uri = url.FILTER_USER_DATA_BY_ORGANOGRAM;
    let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    if (result?.meta?.success) {
      if (topMgmt !== "") {
        return result?.data
          ? Object.values(result?.data?.topManagement)?.map(
              (item) => item?.userData
            )
          : null;
      }
      if (isHead) {
        let data = getChildAtIndex(result?.data);
        return data ?? null;
      } else {
        return result?.data ? extractUserDataByDepartment(result?.data) : null;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.error("GET_ALL_ROLES_API ERROR", error);
  }
};

export const getUsersDataWithResponsibility = async (title) => {
  try {
    let reqObj = {
      orgId: getItem(Constants.KEY_USER_DATA)?.orgId,
      designation: title,
    };
    let uri = url.FILTER_USER_DATA_BY_ORGANOGRAM;
    let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    if (result?.meta?.success) {
      let a = result?.data
        ? Array.isArray(title)
          ? extractUserDataByResponsibility(result?.data, "multiple")
          : extractUserDataByResponsibility(result?.data)
        : null;
      return result?.data
        ? extractUserDataByResponsibility(result?.data)
        : null;
    } else {
      return null;
    }
  } catch (error) {
    console.error("GET_ALL_ROLES_API ERROR", error);
  }
};

export const getChildrenOnDesignation = async (designation, userId) => {
  try {
    let reqObj = {
      orgId: getItem(Constants.KEY_USER_DATA)?.orgId,
      designation: designation,
      userId: userId,
    };
    let uri = url.FILTER_USER_DATA_BY_ORGANOGRAM;
    let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    if (result?.meta?.success) {
      return result?.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("GET_ALL_ROLES_API ERROR", error);
  }
};

export const getTopMgmtDepartment = async (title, isHead = false) => {
  try {
    let reqObj = {
      orgId: getItem(Constants.KEY_USER_DATA)?.orgId,
      defaultDesignation: title,
    };
    let uri = url.GET_TOPMGMT_DATA_BY_ORGANOGRAM;
    let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    if (result?.meta?.success) {
      if (isHead) {
        let data = getChildAtIndex(result?.data);
        return data ?? null;
      } else {
        return result?.data ? extractUserDataByDepartment(result?.data) : null;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.error("GET_ALL_ROLES_API ERROR", error);
  }
};

export const getUserIdsOrUserData = (data, onlyIds = false) => {
  if (onlyIds == true) {
    return data?.map((value) => ({ userId: value?.userId }));
  } else {
    return data?.map((value) => ({
      designation: value?.designation,
      userId: value?.userId,
      name: value?.name,
    }));
  }
};

// New Services

// for group using type
const groupBy = (data, key) =>
  data?.reduce((acc, item) => {
    const groupKey = item?.[key] || "Unknown";
    acc[groupKey] = [...(acc[groupKey] || []), item];
    return acc;
  }, {});

// for different by deparments
export const separateUsersByDepartment = (users) => {
  return users?.reduce((result, user) => {
    const department = user?.department;

    if (!result[department]) {
      result[department] = [];
    }

    result[department]?.push({
      designationId: user?.designationId,
      designation: user?.designation,
      userId: user?.userId,
      userName: user?.userName,
    });

    return result;
  }, {});
};

//seperate department and designation data
export const separateByDepartmentAndUser = (users) => {
  return users?.reduce((result, user) => {
    const { type, department } = user;
    // Ensure a grouping for the user's `type`
    if (!result?.[type]) {
      result[type] = {};
    }
    // Ensure a grouping for the department within the `type`
    if (!result?.[type]?.[department]) {
      result[type][department] = [];
    }
    // Push the user data into the appropriate group
    result[type][department]?.push({
      designationId: user?.designationId,
      designation: user?.designation,
      userId: user?.userId,
      userName: user?.userName,
    });

    return result;
  }, {});
};

//for seperate designations data by department
export const separateUsersByDesignation = (users) => {
  try {
    // Check if users is a valid array
    if (!Array.isArray(users)) {
      throw new Error("Input data is not an array.");
    }

    // Grouping logic
    return users?.reduce((result, user) => {
      const department = user?.department;

      // Ensure department exists before proceeding
      if (!department) {
        console.warn("User is missing a department:", user);
        return result; // Skip this user
      }
      // Ensure a grouping for the department
      if (!result[department]) {
        result[department] = [];
      }
      // Push the user data into the appropriate group
      result[department]?.push({
        userName: user?.userName || "",
        userId: user?.userId || "",
        departmentId: user?.departmentId || "",
        designation: user?.designation || "",
        designationId: user?.designationId || "",
        userDesignation: user?.userDesignation || "",
        type: user?.type || "",
      });

      return result;
    }, {});
  } catch (error) {
    console.error("Error in separateUsersByDesignation:", error.message);
    return {}; // Return an empty object in case of an error
  }
};

// for get data from passing through defaultDesignation
export const getUserDataByDesignation = async (reqObj, isGroup = true) => {
  try {
    let result = await ApiServices.callServicePostWithBodyData(
      organogramUrl.GET_USERS,
      reqObj
    );
    if (result?.meta?.success) {
      if (isGroup) {
        let data = groupBy(result?.data, "type");
        return data;
      } else {
        return result?.data;
      }
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
};

export const getUserDataByDesignationWithoutAuth = async (
  orgId,
  reqObj,
  isGroup = true
) => {
  try {
    let result = await ApiServices.callServicePostWithBodyData(
      organogramUrl.GET_USERS_WITHOUTAUTH + orgId,
      reqObj
    );
    if (result?.meta?.success) {
      if (isGroup) {
        let data = groupBy(result?.data, "type");
        return data;
      } else {
        return result?.data;
      }
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
};
