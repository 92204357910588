import { Box, Card, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";

const CustomFolderSkeleton = ({length=5}) => {
  return (
    <Grid container spacing={2}>
      {Array.from(new Array(length)).map((_, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
          <Card
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                borderRadius: 2,
                padding: 2,
                height: "100%",
                justifyContent: "center",
                cursor: "pointer",
                "&:hover": {
                  transform: "scale3d(1.02, 1.02, 1)",
                  transition: "all .10s ease-in-out",
                },
              }}
            >
              <Skeleton
                variant="rectangular"
                height={"70px"}
                width={"70px"}
                sx={{
                  borderRadius: "12px",
                }}
              />
              <Typography
                sx={{
                  margin: "15px 15px 0 15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Skeleton
                  variant="text"
                  height={15}
                  width={"120px"}
                  sx={{
                    borderRadius: "12px",
                  }}
                />
                <Skeleton
                  variant="text"
                  height={15}
                  width={"90px"}
                  sx={{
                    margin: "",
                    borderRadius: "12px",
                  }}
                />
              </Typography>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CustomFolderSkeleton;
