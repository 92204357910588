import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";

// Define lazy-loaded components
const Report = Loadable(lazy(() => import("../Report")));
const History = Loadable(lazy(() => import("../History")));
const Register = Loadable(lazy(() => import("../Register")));
const ScrapRecord = Loadable(lazy(() => import("../ScrapRecords")));
const ReturnOfGoodsRecord = Loadable(
  lazy(() => import("../ReturnGoodsRecords"))
);
const ReworkRecord = Loadable(lazy(() => import("../ReworkRecord")));
const RejectionRecord = Loadable(lazy(() => import("../RejectionRecord")));
const ConcessionRecord = Loadable(lazy(() => import("../ConcessionRecord")));
const List = Loadable(lazy(() => import("../List")));
const YearList = Loadable(lazy(() => import("../YearList")));
const YearWiseDocument = Loadable(lazy(() => import("../YearWiseDocument")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const CONCPRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.CONTROL_OF_NON_CONFIRMING}`, null, [
      createRoute("product-history/:year", <Register />),
      createRoute("report/:id/:childId", <Report />),
      createRoute("rework-record/:id/:childId", <ReworkRecord />),
      createRoute("rejection-record/:id/:childId", <RejectionRecord />),
      createRoute("return-goods-record/:id/:childId", <ReturnOfGoodsRecord />),
      createRoute("scrap-record/:id/:childId", <ScrapRecord />),
      createRoute("concession-record/:id/:childId", <ConcessionRecord />),
      createRoute("history", <History />),
      createRoute("list/:year", <List/>),
      createRoute("year-list", <YearList/>),
      createRoute("year-wise-document/:documentName/:id", <YearWiseDocument />),
    ]),
  ]
);

export default CONCPRoute;
