import PropTypes from "prop-types";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import withRouter from "./withRouter";

const CustomAccordion = (props) => {
  const {
    expanded,
    onChange,
    summary,
    details,
    expandIcon = <ExpandMoreIcon />,
    accordionProps = {},
    summaryProps = {},
    detailsProps = {},
    disabled,
    id,
    theme,
  } = props;
  return (
    <Accordion
      disabled={disabled}
      style={{
        borderBottom:
          theme.palette.mode === "dark"
            ? "1px solid #f8f9fa0d"
            : "1px solid #dbe0e5a6",
      }}
      expanded={expanded}
      onChange={onChange}
      {...accordionProps}
    >
      <AccordionSummary expandIcon={expandIcon} {...summaryProps}>
        {summary}
      </AccordionSummary>
      <AccordionDetails {...detailsProps}>{details}</AccordionDetails>
    </Accordion>
  );
};

CustomAccordion.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  expandIcon: PropTypes.node,
};

export default withRouter(CustomAccordion);
