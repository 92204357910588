import { useState, useEffect } from "react";
import { getItem, setItem } from "services/utils";

export default function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => {
    const storedValue =
      typeof window !== "undefined" ? getItem(key, false) : null;
    return storedValue === null ? defaultValue : JSON.parse(storedValue);
  });

  useEffect(() => {
    const listener = (e) => {
      if (
        typeof window !== "undefined" &&
        e.storageArea === localStorage &&
        e.key === key
      ) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
      }
    };
    window.addEventListener("storage", listener);

    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = (newValue) => {
    setValue((currentValue) => {
      const result =
        typeof newValue === "function" ? newValue(currentValue) : newValue;
      if (typeof window !== "undefined")
        setItem(key, JSON.stringify(result), false);
      return result;
    });
  };

  return [value, setValueInLocalStorage];
}
