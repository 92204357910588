import { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Button, Stack, Grid, Typography, Card, Box } from "@mui/material";
import { showToast } from "services/utils";
import * as url from "./utils/urlHelper";
import FolderIcon from "@mui/icons-material/Folder";
import withRouter from "components/withRouter";

const CODFormats = (props) => {
  const { userData, ApiServices } = props,
    { navigate, location } = props?.router;
  const searchParams = new URLSearchParams(location.search);
  const documentId = searchParams.get("documentId");
  const [codFormats, setCODFormats] = useState([]);

  const getFormats = async () => {
    try {
      const queryParams = new URLSearchParams({
        orgId: userData?.orgId,
      }).toString();
      let uri = url.GET_PRO_FORMAT + "?" + queryParams;
      let result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        const formatData = result?.data?.map((item) => ({
          ...item,
          proformatId: item?._id,
          title: `${item?.year} - COD`,
        }));
        setCODFormats(formatData);
      } else {
        setCODFormats([]);
      }
    } catch (error) {
      console.error("GET_FORMATS_BY_FORMATNAME ERROR", error);
    }
  };
  useEffect(() => {
    getFormats();
  }, []);

  const getTitle = (title) => {
    return (
      <>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 1, pb: 0, fontSize: 20 }}
        >
          <h5>{title}</h5>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "8px" }}
                onClick={() => handleCreateCOD()}
              >
                Create
              </Button>
          </div>
        </Stack>
      </>
    );
  };

  const handleCreateCOD = async () => {
    try {
      let reqObj = {
        documentName: "Control Of Document",
        orgId: userData?.orgId,
        prefixType: "FormatPrefixes",
        documentId: documentId,
        year: new Date().getFullYear(),
      };
      let uri = url.POST_FORMAT;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      showToast(result);
      if (result?.meta?.success) {
        getFormats();
      }
    } catch (error) {
      console.error("GET_FORMATS_BY_FORMATNAME ERROR", error);
    }
  };

  return (
    <>
      <MainCard title={getTitle("COD Formats")}>
        <Grid container spacing={2}>
          {codFormats &&
            codFormats?.map((format, index) => (
              <>
                <Grid item xs={12} sm={6} md={2} key={index}>
                  <Card
                    sx={{
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      onClick={() => {
                        navigate(
                          `/control-of-document/codChildformat?documentId=${documentId}&proformatId=${format?.proformatId}`
                        );
                      }}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        borderRadius: 2,
                        padding: 2,
                        height: "100%",
                        justifyContent: "center",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale3d(1.02, 1.02, 1)",
                          transition: "all .10s ease-in-out",
                        },
                      }}
                    >
                      <FolderIcon
                        fontSize="large"
                        color="primary"
                        sx={{
                          fontSize: 70,
                        }}
                      />
                      <Typography variant="body2" color="textSecondary" mt={1}>
                        {format?.title ?? "-"}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              </>
            ))}
        </Grid>
      </MainCard>
    </>
  );
};

CODFormats.propTypes = {};

export default withRouter(CODFormats);
