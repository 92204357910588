import Routes from "routes";
import ThemeCustomization from "themes";
import RTLLayout from "components/RTLLayout";
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";
import Notistack from "components/third-party/Notistack";
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import { useContext } from "react";
import { ConfigContext } from "contexts/ConfigContext";
import DownloadModal from "components/reusables/DownloadModal";

const App = () => {
  const { isDownloading } = useContext(ConfigContext);

  return (
    <ThemeCustomization>
      <RTLLayout>
        <ScrollTop>
          <AuthProvider>
            <>
              <Notistack>
                <DownloadModal isOpen={isDownloading} />
                <Routes />
                <Snackbar />
              </Notistack>
            </>
          </AuthProvider>
        </ScrollTop>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
