import * as Yup from "yup";

// for add edit manual
export const AddEditManuals = Yup.object().shape({
  name: Yup.string().required("Quality Manual Name is required"),
  type: Yup.string()
    .oneOf(["Default", "Parent-child", "Custom"], "Invalid type selected")
    .required("Type is required"),
});

// for add edit procedure
export const AddEditProcedure = Yup.object().shape({
  name: Yup.string().required("Procedure Name is required"),
  isMultiPart: Yup.string().required("Multipart is required"),
  parts: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
      })
    )
    .when("isMultiPart", {
      is: "true",
      then: (schema) =>
        schema
          .min(2, "At least two part is required if multi-part is selected")
          .required("Parts are required when multi-part is enabled")
          // Custom test to ensure unique part names
          .test("unique-name", "Each part name must be unique", (parts) => {
            const names = parts?.map((part) => part?.name);
            const uniqueNames = new Set(names);
            return names.length === uniqueNames.size; // True if all names are unique
          }),
      otherwise: (schema) => schema.nullable(),
    }),
});

// add edit formats
export const AddEditFormats = Yup.object().shape({
  // Procedure field validation
  procedure: Yup.mixed()
    .required("Procedure is required")
    .test("is-valid-procedure", "Invalid procedure format", (value) => {
      if (typeof value === "string") {
        return true; // Valid if it's a string (ID)
      }
      if (typeof value === "object" && value?.item) {
        return Array.isArray(value?.item?.parts); // Ensure parts is an array
      }
      return false; // Invalid for other types
    }),
  part: Yup.mixed()
    .nullable()
    .when("procedure", {
      is: (val) =>
        Array.isArray(val?.item?.parts) && val?.item?.parts?.length > 0,
      then: (schema) => schema.required("Part is required"),
    }),
  //   "validate-part",
  //   "Part is required when procedure has parts",
  //   (value, context) => {
  //     const procedure = context?.parent?.procedure;

  //     // If procedure has parts, validate `part`
  //     if (
  //       Array.isArray(procedure?.item?.parts) &&
  //       procedure?.item?.parts?.length > 0
  //     ) {
  //       return value && value?.label && value?.value; // Ensure `part` is valid
  //     }

  //     // Skip validation if `procedure.item.parts` is missing or empty
  //     return true;
  //   }
  // ),
  // Documents validation
  documents: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Document name is required"),
        viewType: Yup.string()
          .oneOf(["List", "Year"], "Invalid view type")
          .required("View type is required"),
      })
    )
    .min(1, "At least one document must be added")
    .required("Documents are required"),
});
