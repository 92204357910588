import PropTypes from "prop-types";
import { useEffect } from "react";
import useAuth from "hooks/useAuth";
import withRouter from "components/withRouter";

const AuthGuard = (props) => {
  const { isLoggedIn } = useAuth();
  const { navigate, location } = props?.router,
    { children } = props;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/", {
        state: {
          from: location.pathname,
        },
        replace: true,
      });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default withRouter(AuthGuard);
