import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import CORChildFormats from "../CORChildFormats";
import CORFormats from "../CORFormats";
import MasterListOfQualityRecords from "../MasterListOfQualityRecords";

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const CORRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("control-of-record", null, [
      createRoute("cor-format", <CORFormats />),
      createRoute("cor-child-format", <CORChildFormats />),
      createRoute("master-List", <MasterListOfQualityRecords />),
    ]),
  ]
);

export default CORRoutes;
