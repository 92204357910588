import { Constants } from "./keyConstants";
import constant from "./constant";
import { getItem } from "./utils";

/***************************** API URL **************************/
// const apiUrl = process.env.REACT_APP_API_URL;
const apiUrl = constant.API_URL;
/******************************************************************/

export const ApiServices = {
  callServicePostWithBodyData: async (URL, apiBody) => {
    try {
      let token = getItem(Constants.KEY_USER_TOKEN);
      const response = await fetch(apiUrl + URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(apiBody),
      });
      const responseData = await response.json();
      if (!responseData) {
        throw new Error("Empty response data");
      }
      return responseData;
    } catch (error) {
      console.error("error", apiUrl + URL, ">>>>>>>", error);
      switch (error.message) {
        case Constants.ERROR_COMETCHAT_LOGOUT:
          throw new Error(Constants.ERROR_MESSAGE_COMETCHAT_LOGOUT);
        case Constants.ERROR_NETWORK_REQUEST_FAILED:
          throw new Error(Constants.NO_INTERNET);
        default:
          throw error;
      }
    }
  },
  callServicePutWithBodyData: async (URL, apiBody) => {
    try {
      let token = getItem(Constants.KEY_USER_TOKEN);
      const response = await fetch(apiUrl + URL, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(apiBody),
      });
      const responseData = await response.json();
      if (!responseData) {
        throw new Error("Empty response data");
      }
      return responseData;
    } catch (error) {
      console.error("error", apiUrl + URL, ">>>>>>>", error);
      switch (error.message) {
        case Constants.ERROR_COMETCHAT_LOGOUT:
          throw new Error(Constants.ERROR_MESSAGE_COMETCHAT_LOGOUT);
        case Constants.ERROR_NETWORK_REQUEST_FAILED:
          throw new Error(Constants.NO_INTERNET);
        default:
          throw error;
      }
    }
  },
  callServiceGet: async (URL) => {
    try {
      let token = getItem(Constants.KEY_USER_TOKEN);
      const response = await fetch(apiUrl + URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const responseData = await response.json();
      if (!responseData) {
        throw new Error("Empty response data");
      }
      return responseData;
    } catch (error) {
      console.error("error", apiUrl + URL, ">>>>>>>", error);
      switch (error.message) {
        case Constants.ERROR_COMETCHAT_LOGOUT:
          throw new Error(Constants.ERROR_MESSAGE_COMETCHAT_LOGOUT);
        case Constants.ERROR_NETWORK_REQUEST_FAILED:
          throw new Error(Constants.NO_INTERNET);
        default:
          throw error;
      }
    }
  },
  callServicePostWithFormData: async (URL, apiBody) => {
    try {
      let token = getItem(Constants.KEY_USER_TOKEN);
      const response = await fetch(apiUrl + URL, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: apiBody,
      });
      const responseData = await response.json();
      if (!responseData) {
        throw new Error("Empty response data");
      }
      return responseData;
    } catch (error) {
      console.error("error", apiUrl + URL, ">>>>>>>", error);
      switch (error.message) {
        case Constants.ERROR_COMETCHAT_LOGOUT:
          throw new Error(Constants.ERROR_MESSAGE_COMETCHAT_LOGOUT);
        case Constants.ERROR_NETWORK_REQUEST_FAILED:
          throw new Error(Constants.NO_INTERNET);
        default:
          throw error;
      }
    }
  },
  callServicePutWithFormData: async (URL, apiBody) => {
    try {
      let token = getItem(Constants.KEY_USER_TOKEN);
      const response = await fetch(apiUrl + URL, {
        method: "PUT",
        headers: {
          Authorization: token,
        },
        body: apiBody,
      });
      const responseData = await response.json();
      if (!responseData) {
        throw new Error("Empty response data");
      }
      return responseData;
    } catch (error) {
      console.error("error", apiUrl + URL, ">>>>>>>", error);
      switch (error.message) {
        case Constants.ERROR_COMETCHAT_LOGOUT:
          throw new Error(Constants.ERROR_MESSAGE_COMETCHAT_LOGOUT);
        case Constants.ERROR_NETWORK_REQUEST_FAILED:
          throw new Error(Constants.NO_INTERNET);
        default:
          throw error;
      }
    }
  },
  callServiceDelete: async (URL) => {
    try {
      let token = getItem(Constants.KEY_USER_TOKEN);
      const response = await fetch(apiUrl + URL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const responseData = await response.json();
      if (!responseData) {
        throw new Error("Empty response data");
      }
      return responseData;
    } catch (error) {
      console.error("error", apiUrl + URL, ">>>>>>>", error);
      switch (error.message) {
        case Constants.ERROR_COMETCHAT_LOGOUT:
          throw new Error(Constants.ERROR_MESSAGE_COMETCHAT_LOGOUT);
        case Constants.ERROR_NETWORK_REQUEST_FAILED:
          throw new Error(Constants.NO_INTERNET);
        default:
          throw error;
      }
    }
  },
};
