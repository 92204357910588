import { useState, useEffect } from "react";
import { TextField, Typography, Box, Button, Grid } from "@mui/material";
import CustomAccordionSkeleton from "components/CustomAccordionSkeleton";
import CustomAccordion from "components/CustomAccordian";
import Error404 from "components/reusables/404";
import * as url from "./utils/urlHelper";
import DownloadModal from "components/reusables/DownloadModal";
import MainCard from "components/MainCard";
import TabsContainer from "components/TabsContainer";
import {
  downloadFormat,
  extractUserIdAndName,
  sendNotification,
  showToast,
} from "services/utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getUserDataByDesignation } from "services/organogramServices";
import withRouter from "components/withRouter";
import CustomNotification from "components/CustomNotification";
import { routeConstants } from "services/keyConstants";

const ServiceRecordHistory = (props) => {
  const { params } = props?.router,
    {
      userData,
      ApiServices,
      onChangeDownloadState,
      TypeConstants,
      DepartmentConstants,
    } = props;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [isDownload, setIsDownload] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const year = params?.year;

  const getData = async () => {
    try {
      const reqObj = {
        year: year,
        demandedItem:
          tabValue === 0
            ? "Customer Service Record"
            : "Customer Servicing Details",
      };
      const result = await ApiServices.callServicePostWithBodyData(
        url.GET_ALL_SERVICE_FEEDBACK,
        reqObj
      );
      if (result?.meta?.success) {
        setData(result?.data?.list || []);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadClick = async (e, id) => {
    let reqObj = {
      formatId: id,
    };
    let uri = url.DOWNLOAD;
    await downloadFormat(uri, reqObj, onChangeDownloadState);
  };

  useEffect(() => {
    getData();
  }, [tabValue]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const tabLabels = ["Customer Record", "Customer Details"];

  const tabContents = [
    () => (
      <CustomAccordionList
        isLoading={isLoading}
        data={data}
        expanded={expanded}
        handleAccordionChange={handleAccordionChange}
        type="Customer Service Record"
        isMakeDecisionButton={false}
        handleDownloadClick={handleDownloadClick}
      />
    ),
    () => (
      <CustomAccordionList
        isLoading={isLoading}
        data={data}
        expanded={expanded}
        handleAccordionChange={handleAccordionChange}
        type="Customer Servicing Details"
        setNotificationModalOpen={setNotificationModalOpen}
        notificationModalOpen={notificationModalOpen}
        handleSubmitNotification={handleSubmitNotification}
        setSelectedId={setSelectedId}
        isMakeDecisionButton={true}
        handleDownloadClick={handleDownloadClick}
      />
    ),
  ];

  const handleSubmitNotification = async (selectedUser) => {
    try {
      let userDatas = await getUserDataByDesignation(
        [
          {
            type: TypeConstants.TYPE_FIND_HEAD,
            department: [DepartmentConstants.QUALITY_CONTROL_DEPARTMENT],
          },
        ],
        false
      );
      let reqObj = {
        id: selectedId,
        orgId: userData?.orgId,
        documentName: "Customer Servicing Details",
        prefixType: "FormatPrefixes",
        updateData: {
          notificationUser: userDatas,
          ...(selectedUser && {
            selectedUser: extractUserIdAndName(selectedUser),
          }),
          "document.makeDecision": true,
        },
      };

      let uri = url.UPDATE_SPECIFIC_DATA;
      const response = await ApiServices.callServicePutWithBodyData(
        uri,
        reqObj
      );
      if (selectedUser) {
        let notificationObj = {
          dataArray: [
            {
              recipients: [
                { userId: extractUserIdAndName(selectedUser)?.userId },
              ],
              msg: "Servicing is coming. Please prepare for the service record.",
              status: "Please Fill the service record",
              location: `${routeConstants.CUSTOMER_SERVICE}/service-record/${response?.data?._id}`,
            },
          ],
        };

        await sendNotification(notificationObj);
        getData();
      }

      showToast(response);
    } catch (error) {
      console.error("Error in handleSubmitNotification:", error);
    }
  };

  return (
    <>
      <DownloadModal isOpen={isDownload} setIsClose={setIsDownload} />
      <MainCard title="Service Record History">
        <TabsContainer
          tabLabels={tabLabels}
          tabContents={tabContents}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
      </MainCard>
    </>
  );
};

const CustomAccordionList = ({
  isLoading,
  data,
  expanded,
  handleAccordionChange,
  type,
  setNotificationModalOpen,
  notificationModalOpen,
  handleSubmitNotification,
  setSelectedId,
  isMakeDecisionButton,
  handleDownloadClick,
}) => {
  const filteredData = data?.filter((item) => item.documentName === type);
  const handleDesicionModal = (item) => {
    setSelectedId(item);
    setNotificationModalOpen(!notificationModalOpen);
  };
  return (
    <>
      {notificationModalOpen ? (
        <CustomNotification
          open={notificationModalOpen}
          setOpen={setNotificationModalOpen}
          isMultipleUser={true}
          handleSubmit={handleSubmitNotification}
        />
      ) : null}

      {isLoading ? (
        <CustomAccordionSkeleton />
      ) : filteredData?.length > 0 ? (
        filteredData?.map((item, index) => (
          <CustomAccordion
            key={item._id}
            expanded={expanded === index}
            onChange={handleAccordionChange(index)}
            summary={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography>{item?.document?.customerName}</Typography>
                <Typography>{item?.document?._id}</Typography>
                {isMakeDecisionButton &&
                  item.document.makeDecision === false && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleDesicionModal(item?._id)}
                      >
                        Make Decision
                      </Button>
                    </Grid>
                  )}

                <Button
                  variant="contained"
                  color="success"
                  onClick={(e) => handleDownloadClick(e, item?._id)}
                >
                  Download
                </Button>
              </div>
            }
            details={
              type === "Customer Servicing Details" ? (
                <Typography component="div">
                  <Box mb={2}>
                    <Typography variant="h6">Customer Name</Typography>
                    <TextField
                      fullWidth
                      value={item?.document?.customerName || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  </Box>

                  <Box mb={2}>
                    <Typography variant="h6">Customer Address</Typography>
                    <TextField
                      fullWidth
                      value={item?.document?.customerAddress || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  </Box>

                  <Box mb={2}>
                    <Typography variant="h6">Date and Time Event</Typography>
                    <TextField
                      fullWidth
                      value={item?.document?.dateAndTimeEvent || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  </Box>

                  <Box mb={2}>
                    <Typography variant="h6">Device Details</Typography>
                    <TextField
                      fullWidth
                      value={item.document?.deviceDetails || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  </Box>

                  <Box mb={2}>
                    <Typography variant="h6">Description of Problem</Typography>
                    <TextField
                      fullWidth
                      value={item?.document?.descriptionOfProblem || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  </Box>

                  <Box mb={2}>
                    <Typography variant="h6">Servicing At</Typography>
                    <TextField
                      fullWidth
                      value={item?.document?.servicingAt || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  </Box>
                </Typography>
              ) : (
                <Box style={{ padding: "20px", backgroundColor: "#f9f9f9" }}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Customer Name"
                      variant="outlined"
                      name="customerName"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.customerName}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Customer Address"
                      variant="outlined"
                      name="customerAddress"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.customerAddress}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      name="email"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Phone No."
                      variant="outlined"
                      name="phoneNo"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.phoneNo}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Originator/Engineer Name"
                      variant="outlined"
                      name="originatorName"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.originatorName}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ mb: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Calibration Date"
                        format="YYYY-MM-DD"
                        name="calibrationDate"
                        sx={{ width: "100%" }}
                        value={
                          item?.document?.dateAndTimeEvent
                            ? dayjs(item?.document?.dateAndTimeEvent)
                            : null
                        }
                        onChange={(date) => {
                          const selectedDate = date
                            ? date.format("YYYY-MM-DD")
                            : "";
                          handleChangeDate({
                            target: {
                              name: "calibrationDate",
                              value: selectedDate,
                            },
                          });
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Service Record Number"
                      variant="outlined"
                      name="serviceRecordNumber"
                      sx={{ mb: 3 }}
                      value={item?.document?.serviceRecordNumber}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      Device Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Device Name"
                      variant="outlined"
                      style={{ marginBottom: "20px" }}
                      name="deviceName"
                      value={item?.document?.deviceName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Lot/Serial No."
                      variant="outlined"
                      name="lotSerialNo"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.lotSerialNo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="UDI/UPC"
                      variant="outlined"
                      name="udiUpc"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.udiUpc}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Lot/Serial No. of Component"
                      variant="outlined"
                      name="lotSerialNoComponent"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.lotSerialNoComponent}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      Details of Return
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Shipping Carrier"
                      variant="outlined"
                      name="shippingCarrier"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.shippingCarrier}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Tracking Number"
                      variant="outlined"
                      name="trackingNumber"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.trackingNumber}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description of Problem"
                      variant="outlined"
                      name="descriptionOfProblem"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.descriptionOfProblem}
                      multiline
                      rows={4}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Occurrence of Injury or Death"
                      variant="outlined"
                      name="occurrenceOfInjury"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.occurrenceOfInjury}
                      multiline
                      rows={2}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Did the customer claim that the device did not meet the specification"
                      variant="outlined"
                      name="deviceSpecificationClaim"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.deviceSpecificationClaim}
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="If the response is yes; initiate complaint records"
                      variant="outlined"
                      name="complaintRecordReferenceNo"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.complaintRecordReferenceNo}
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="If the response is yes: initiate reporting"
                      variant="outlined"
                      name="reportingToRegulatoryBodies"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.reportingToRegulatoryBodies}
                      multiline
                      rows={2}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Reference No. of Complaint Record"
                      variant="outlined"
                      name="referenceNoComplaintRecord"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.referenceNoComplaintRecord}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Does it require reporting to regulatory bodies"
                      variant="outlined"
                      name="reportingToRegulatoryBodies"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.reportingToRegulatoryBodies}
                      multiline
                      rows={2}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Report Reference No."
                      variant="outlined"
                      name="reportReferenceNo"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.reportReferenceNo}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      Service Engineer Findings
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Action Taken by Service Engineer"
                      variant="outlined"
                      name="actionTakenByServiceEngineer"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.actionTakenByServiceEngineer}
                      multiline
                      rows={4}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Reference of Non-Conforming Report"
                      variant="outlined"
                      name="nonConformingReportReference"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.nonConformingReportReference}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="CAPA Number"
                      variant="outlined"
                      name="capaNumber"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.capaNumber}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Calibration Date"
                        format="YYYY-MM-DD"
                        name="dateWhenProblemResolved"
                        sx={{ width: "100%" }}
                        value={
                          item?.document?.dateWhenProblemResolved
                            ? dayjs(item?.document?.dateWhenProblemResolved)
                            : null
                        }
                        onChange={(date) => {
                          const selectedDate = date
                            ? date.format("YYYY-MM-DD")
                            : "";
                          handleChangeDate({
                            target: {
                              name: "dateWhenProblemResolved",
                              value: selectedDate,
                            },
                          });
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      Review and Closure of Service Record
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="name"
                      variant="outlined"
                      name="signature"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.esign?.userData?.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="time"
                      variant="outlined"
                      name="name"
                      style={{ marginBottom: "20px" }}
                      value={item?.document?.esign?.time}
                    />
                  </Grid>
                </Box>
              )
            }
          />
        ))
      ) : (
        <Error404
          headingMain="Oops, looks like there are no records!"
          subHeading="Please check back later or create a new record"
        />
      )}
    </>
  );
};

export default withRouter(ServiceRecordHistory);
