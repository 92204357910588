import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";

// render - pages
const ListOfCustomerProperty = Loadable(
  lazy(() => import("../ListOfCustomerProperty"))
);

const CustomerPropertyReportForm = Loadable(
  lazy(() => import("../CustomerPropertyReportForm"))
);

const ApprovalCustomerProperty = Loadable(
  lazy(() => import("../ApprovalCustomerProperty"))
);

const YearList = Loadable(lazy(() => import("../YearList")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

const CPRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.CONTROL_OF_CUSTOMER_PROPERTY}`, null, [
      createRoute("list-of-customer-property", <ListOfCustomerProperty />),
      createRoute(
        `customer-property-report/:id`,
        <CustomerPropertyReportForm />
      ),
      createRoute(
        "approval-property-report/:year",
        <ApprovalCustomerProperty />
      ),
      createRoute("year-list", <YearList />),
    ]),
  ]
);

export default CPRoutes;
