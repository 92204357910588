import { useCallback, useState } from "react";
import IconButton from "components/@extended/IconButton";
import { Maximize1 } from "iconsax-react";
import withRouter from "components/withRouter";
import CustomTooltip from "components/reusables/CustomTooltip";
import { Box } from "@mui/material";

const FullScreen = (props) => {
  const { theme } = props,
    { ThemeMode } = props?.config;

  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
    if (document && !document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }, []);

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK
      ? "background.paper"
      : "secondary.200";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK
      ? "background.default"
      : "secondary.100";

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <CustomTooltip title={open ? "Exit Fullscreen" : "Fullscreen"}>
        <IconButton
          color="secondary"
          variant="light"
          onClick={handleToggle}
          size="large"
          sx={{
            color: "secondary.main",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
            p: 1,
          }}
        >
          <Maximize1
            variant="Bulk"
            {...(open && { style: { transform: "rotate(180deg)" } })}
          />
        </IconButton>
      </CustomTooltip>
    </Box>
  );
};

export default withRouter(FullScreen);
