import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import MainCard from "components/MainCard";
import { getTitle } from "services/utils";
import withRouter from "components/withRouter";
import DynamicFolder from "components/DynamicFolder";
import { GET_LIST_MONTH } from "./utils/urlHelper";

const FormatListByMonth = (props) => {
  const { navigate } = props?.router,
    { ApiServices } = props;

  const [format, setFormat] = useState([]);
  const { moduleName, year } = props?.router?.params;

  const [isLoading, setIsLoading] = useState(true);

  const getAllCreatedFormat = async () => {
    setIsLoading(true);
    try {
      let reqObj = {
        modelName: moduleName,
        year: parseInt(year),
      };

      let uri = GET_LIST_MONTH;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);

      if (result?.response) {
        setFormat(result?.result);
      }
    } catch (error) {
      console.error("GET_LIST_MONTH API ERROR", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCreatedFormat();
  }, []);

  const handleProcedures = async (item) => {
    navigate(`/format/by-month/${moduleName}/${year}/${item.month}`);
  };

  return (
    <MainCard title={getTitle("Month wise list")}>
      <Box>
        <DynamicFolder
          isLoading={isLoading}
          onClick={handleProcedures}
          data={format}
          title={"month"}
          headingMain={`Oops, looks like there are no format created it!`}
          subHeading={`Please create format`}
        />
      </Box>
    </MainCard>
  );
};
export default withRouter(FormatListByMonth);
