import React, { useState, useEffect } from "react";
import {
  Snackbar,
  Alert,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import doneIcon from "assets/images/notification/doneIcon.svg";
import errorIcon from "assets/images/notification/errorIcon.svg";
import NotificationSound from "assets/Sounds/notificationSound.wav";
import { Constants } from "services/keyConstants";
import { getItem } from "services/utils";
import withRouter from "./withRouter";

const NotificationStyle = (props) => {
  const { notification, severity = "success" ,theme} = props;
  const smallScreen = useMediaQuery("maxWidth:768px");
  const [open, setOpen] = useState(false);
  const [soundOn, setSoundOn] = useState(
    getItem(Constants.KEY_NOTIFICATION_SOUND)
  );

  useEffect(() => {
    if (notification) {
      setOpen(true);
    }
    if (soundOn) {
      playNotificationSound();
    }
  }, [notification]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const playNotificationSound = () => {
    const audio = new Audio(NotificationSound);
    audio.play();
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(" ")}...`;
    }
    return text;
  };

  const TextWithEllipsis = ({ varient, text, wordLimit }) => {
    return (
      <Typography variant={varient}>{truncateText(text, wordLimit)}</Typography>
    );
  };

  useEffect(() => {
    const handleUpdateData = () => {
      setSoundOn(getItem(Constants.KEY_NOTIFICATION_SOUND));
    };

    window.addEventListener("notificationSoundUpdated", handleUpdateData);

    return () => {
      window.removeEventListener("notificationSoundUpdated", handleUpdateData);
    };
  }, []);

  const getGradientBackground = (severity) => {
    switch (severity) {
      case "error":
        return "linear-gradient(90deg, rgba(255, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) 100%)";
      case "success":
      default:
        return "linear-gradient(90deg, rgba(0, 255, 0, 0.1) 0%, rgba(255, 255, 255, 0) 100%)";
    }
  };

  const getNotificationIcon = (severity) => {
    switch (severity) {
      case "error":
        return errorIcon;
      case "success":
      default:
        return doneIcon;
    }
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      sx={{
        left: smallScreen ? "0" : "auto",
        width: "500px",
        "& .MuiSnackbarContent-root": {
          width: "400px",
        },
      }}
    >
      <Alert
        onClose={handleClose}
        severity="none"
        icon={false}
        sx={{
          height: "76.78px",
          position: "relative",
          color: theme.palette.mode === "dark" ? "white" : "black",
          border:
            theme.palette.mode === "dark"
              ? "2px solid #585e64"
              : "2px solid white",
          borderRadius: "8px",
          background: theme.palette.mode === "dark" ? "#242C32" : "white",
          boxShadow:
            theme.palette.mode === "dark"
              ? "0 0 10px 2px #292929"
              : "0 0 20px 2px #e3e3e3",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "8px",
            background: getGradientBackground(severity),
            zIndex: 0,
            "& .MuiAlert-message": {
              overflow: "unset",
            },
          },
        }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "10px",
              right: 0,
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </IconButton>
        }
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            zIndex: 111,
            gap: "10px",
            marginRight: "3px",
          }}
        >
          <Box
            style={{
              background: theme.palette.mode === "dark" ? "#1b1f23" : "#fff",
              height: "45px",
              width: "50px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 100,
            }}
          >
            <Box
              style={{ width: "22px" }}
              component="img"
              src={getNotificationIcon(severity)}
              alt="notificationIcon"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <TextWithEllipsis
                variant="subtitle1"
                sx={{ fontWeight: "bold" }}
                text={notification?.title}
                wordLimit={5}
              />
              <Typography
                variant="span"
                sx={{ color: "#8d8d8d", marginLeft: "1rem" }}
              >
                now
              </Typography>
            </Typography>
            <TextWithEllipsis
              varient="body2"
              text={notification?.message}
              wordLimit={20}
            />
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default withRouter(NotificationStyle);
