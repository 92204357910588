export const GET_PRO_FORMAT = `/cor/pro-format`;

export const POST_FORMAT = `/cor/format`;

export const GET_BY_PROFORMAT_ID = `/cor/format/by-proformatId`;

export const POST_VERSION_LIST = `/cor/format/version-list`;

export const GET_MASTER_LIST = `/cor/format/get-records`;

export const UPDATE_MASTER_LIST = `/cor/format/`;

export const DOWNLOAD_COR = `/cor/format/download`;
