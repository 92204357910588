import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

const ManagementReviewFormats = Loadable(
  lazy(() => import("../ManagementReviewFormats"))
);
const MgmtReviewHistory = Loadable(lazy(() => import("../MgmtReviewHistory")));
const MRMChildFormats = Loadable(lazy(() => import("../MRMChildFormats")));
const MRMPlan = Loadable(lazy(() => import("../MRMPlan")));
const MRMCircular = Loadable(lazy(() => import("../MRMCircular")));
const MinutesOfMeeting = Loadable(lazy(() => import("../MinutesOfMeeting")));
const MrmChildformatDetails = Loadable(
  lazy(() => import("../MrmChildformatDetails"))
);
const createRoute = (path, element, children) => ({ path, element, children });

const MRMRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("mrm", null, [
      createRoute("format-details", <ManagementReviewFormats />),
      createRoute("history", <MgmtReviewHistory />),
      createRoute("year-formats", <MrmChildformatDetails />),
      createRoute("mrmChildFormats", <MRMChildFormats />),
      createRoute("mrmPlan", <MRMPlan />),
      createRoute("mrmCircular", <MRMCircular />),
      createRoute("minutes-of-meeting", <MinutesOfMeeting />),
    ]),
  ]
);

export default MRMRoutes;
