import React, { useRef, useState } from "react";
import {
  Box,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  Popover,
  Typography,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import CustomFolderSkeleton from "./CustomFolderSkeleton";
import Error404 from "components/reusables/404";
import { getNestedValue } from "services/utils";
import withRouter from "./withRouter";
import { Edit2 } from "iconsax-react";

const DynamicFolder = (props) => {
  const {
    isLoading = false,
    data = [],
    title = "",
    onClick,
    headingMain = "Oops, looks like there are no data",
    subHeading = "",
    updateDialogState,
    field = "",
    dynamicTitle = "Do you want to change the name?",
    isEditable = false,
    filter,
  } = props;
  const { theme } = props;
  const [pressedItemId, setPressedItemId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleRightClick = (event, item) => {
    event.preventDefault();
    setPressedItemId(item?.moduleId);
    setAnchorEl(event.currentTarget);
    updateDialogState({
      isDialogOpen: false,
      currentItem: item,
      changeName: item?.name,
    });
  };

  return (
    <>
      {isLoading ? (
        <CustomFolderSkeleton length={12} />
      ) : data?.length > 0 ? (
        filter?.viewMode === "grid" ? (
          <Grid container spacing={3}>
            {data?.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                <Card
                  elevation={1}
                  style={{
                    height: "100%",
                    borderRadius: 12,
                    cursor: "pointer",
                    transition: "transform 0.2s,",
                  }}
                >
                  <Box
                    onContextMenu={(event) => handleRightClick(event, item)}
                    onClick={() => {
                      onClick(item);
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      borderRadius: 2,
                      padding: 2,
                      height: "100%",
                      justifyContent: "center",
                      "&:hover": {
                        transform: "scale(1.03)",
                        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                  >
                    <FolderIcon
                      sx={{
                        fontSize: 80,
                        color: "#5c8ee0",
                        marginBottom: 2,
                      }}
                    />
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {field !== ""
                        ? getNestedValue(item, field)
                        : item?.[title] || item?.name || "-"}
                    </Typography>
                    {isEditable && pressedItemId === item?.moduleId && (
                      <Popover
                        open={Boolean(pressedItemId)}
                        anchorEl={anchorEl}
                        onClose={() => setPressedItemId(null)}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "center",
                          horizontal: "center",
                        }}
                        sx={{
                          borderRadius: 2,
                          padding: 2,
                          boxShadow: 3,
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box
                          onClick={(e) => e.stopPropagation()}
                          sx={{ minWidth: "300px", padding: ".5rem" }}
                        >
                          <List component="nav">
                            <ListItem
                              onClick={() => {
                                updateDialogState((prev) => ({
                                  ...prev,
                                  isDialogOpen: true,
                                }));
                                setPressedItemId(null);
                              }}
                              sx={{
                                padding: "5px 16px",
                                display: "flex",
                                alignItems: "center",
                                gap: ".5rem",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor:
                                    theme.palette.mode === "dark"
                                      ? "#1e1e1e"
                                      : "#f4f4f4",
                                  color:
                                    theme.palette.mode === "dark"
                                      ? "#fff"
                                      : "black",
                                },
                              }}
                            >
                              <Edit2 size="15" />
                              <Typography variant="body2">Rename</Typography>
                            </ListItem>
                            <Divider />
                          </List>
                        </Box>
                      </Popover>
                    )}
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <List>
            {data?.map((item, index) => (
              <ListItem
                key={index}
                onContextMenu={(event) => handleRightClick(event, item)}
                onClick={() => {
                  onClick(item);
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  padding: "10px 16px",
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#1e1e1e" : "#f4f4f4",
                  },
                }}
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <FolderIcon sx={{ color: "#5c8ee0", fontSize: 40 }} />
                  <Typography variant="body1">
                    {field !== ""
                      ? getNestedValue(item, field)
                      : item?.[title] || item?.name || "-"}
                  </Typography>
                </Box>
                {isEditable && pressedItemId === item?.moduleId && (
                  <Popover
                    open={Boolean(pressedItemId)}
                    anchorEl={anchorEl}
                    onClose={() => setPressedItemId(null)}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    sx={{
                      borderRadius: 2,
                      padding: 2,
                      boxShadow: 3,
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Box
                      onClick={(e) => e.stopPropagation()}
                      sx={{ minWidth: "300px", padding: ".5rem" }}
                    >
                      <List component="nav">
                        <ListItem
                          onClick={() => {
                            updateDialogState((prev) => ({
                              ...prev,
                              isDialogOpen: true,
                            }));
                            setPressedItemId(null);
                          }}
                          sx={{
                            padding: "5px 16px",
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? "#1e1e1e"
                                  : "#f4f4f4",
                              color:
                                theme.palette.mode === "dark"
                                  ? "#fff"
                                  : "black",
                            },
                          }}
                        >
                          <Edit2 size="15" />
                          <Typography variant="body2">Rename</Typography>
                        </ListItem>
                        <Divider />
                      </List>
                    </Box>
                  </Popover>
                )}
              </ListItem>
            ))}
          </List>
        )
      ) : (
        <Error404 headingMain={headingMain} subHeading={subHeading} />
      )}
    </>
  );
};

export default withRouter(DynamicFolder);
