import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// Define lazy-loaded components
const CorrectiveAction = Loadable(lazy(() => import("../CorrectiveAction")));
const PreventiveAction = Loadable(lazy(() => import("../PreventiveAction")));
const CAPAList = Loadable(lazy(() => import("../CapaList")));
const CAPAById = Loadable(lazy(() => import("../CAPAById")));
const YearList = Loadable(lazy(() => import("../YearList")));
const List = Loadable(lazy(() => import("../List")));
const CorrectiveActionList = Loadable(
  lazy(() => import("../CorrectiveActionList"))
);
const PreventiveActionList = Loadable(
  lazy(() => import("../PreventiveActionList"))
);

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const CAPARoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("capa", null, [
      createRoute("corrective", <CorrectiveAction />),
      createRoute("preventive", <PreventiveAction />),
      createRoute("corrective-action-list", <CorrectiveActionList />),
      createRoute("preventive-action-list", <PreventiveActionList />),
      createRoute("year-list/:type", <YearList />),
      createRoute("list", <List />),
      createRoute("capa", <CAPAList />),
      createRoute("capadetails", <CAPAById />),
    ]),
  ]
);

export default CAPARoutes;
