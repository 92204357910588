import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { FormatConstants, routeConstants } from "services/keyConstants";
import { ConvertToKebabCase } from "services/utils";

const YearList = Loadable(lazy(() => import("../YearList")));
const CATFormatDetails = Loadable(lazy(() => import("../CATFormatDetails")));
const CATFormat = Loadable(lazy(() => import("../CATFormat")));
const CATFormatHiring = Loadable(lazy(() => import("../CATFormatHiring")));
const CompetencyMatrix = Loadable(lazy(() => import("../CompetencyMatrix")));
const RequisitionForManPower = Loadable(
  lazy(() => import("../RequisitionForManPower"))
);
const InductionTrainingRecord = Loadable(
  lazy(() => import("../InductionTrainingRecord"))
);
const TrainingEvaluation = Loadable(
  lazy(() => import("../TrainingEvaluation"))
);
const ManPowerListWithSpecimenSignature = Loadable(
  lazy(() => import("../ManPowerListWithSpecimenSignature"))
);
const CATFormatTraining = Loadable(lazy(() => import("../CATFormatTraining")));
const TrainingNeedAssessment = Loadable(
  lazy(() => import("../TrainingNeedAssessment"))
);
const TrainingCalender = Loadable(lazy(() => import("../TrainingCalender")));
const TrainingAttendanceSheet = Loadable(
  lazy(() => import("../TrainingAttendanceSheet"))
);
const TrainingEvaluationRevaluation = Loadable(
  lazy(() => import("../TrainingEvaluationRevaluation"))
);
const TrainingEvaluationRevolutionFill = Loadable(
  lazy(() => import("../TrainingEvaluationRevolutionFill"))
);
const ManPowerListData = Loadable(lazy(() => import("../ManPowerListData")));
const TrainingEvaluationFill = Loadable(
  lazy(() => import("../TrainingEvaluationFill"))
);
const RequisitionSavedData = Loadable(
  lazy(() => import("../RequisitionSavedData"))
);
const InductionSavedData = Loadable(
  lazy(() => import("../InductionSavedData"))
);
const InductionTraineeData = Loadable(
  lazy(() => import("../InductionTraineeData"))
);

const createRoute = (path, element, children) => ({ path, element, children });

const CATRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.CAT, null, [
      createRoute("view/:type", <YearList />),
      createRoute(`${ConvertToKebabCase(FormatConstants.CAT.COMPETENCY_MATRIX)}/:year`, <CompetencyMatrix />),
      createRoute("catFormat", <CATFormat />),
      createRoute("cat-FormatDetails", <CATFormatDetails />),
      createRoute("catHiringChildFormats", <CATFormatHiring />),
      createRoute("catTrainingChildFormats", <CATFormatTraining />),
      createRoute("requition-manpower", <RequisitionForManPower />),
      createRoute("manpower-list", <ManPowerListWithSpecimenSignature />),
      createRoute("induction-training-record", <InductionTrainingRecord />),
      createRoute("training-evaluation", <TrainingEvaluation />),
      createRoute("training-assessment", <TrainingNeedAssessment />),
      createRoute("training-calender", <TrainingCalender />),
      createRoute("training-attendece", <TrainingAttendanceSheet />),
      createRoute(
        "training-evaluation-revaluation",
        <TrainingEvaluationRevaluation />
      ),
      createRoute(
        "training-evaluation-revaluation-fill",
        <TrainingEvaluationRevolutionFill />
      ),
      createRoute("manPowerList-Data", <ManPowerListData />),
      createRoute("training-evaluation-fill", <TrainingEvaluationFill />),
      createRoute("saved-requition-manpower", <RequisitionSavedData />),
      createRoute("saved-inductionTrainerData", <InductionSavedData />),
      createRoute("saved-inductionTraineeData", <InductionTraineeData />),
      createRoute("training-evaluation-fill", <TrainingEvaluationFill />),
    ]),
  ]
);

export default CATRoutes;
